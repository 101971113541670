import { useEffect, useState } from "react";
import BaseLayout, { MetaTags } from "../../layout/BaseLayout";
import MetaDecorator from "../../layout/MetaDecorator";
import * as S from "./MainSection/Mainection.styled";
import Calendar from "./../../../images/common/calendar-red.svg";
import Shield from "./../../../images/common/shield-red.svg";
import Point from "./../../../images/common/square-red.svg";
import JobRequestForm from "./JobForm";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { mokJobs } from "./MainSection/MainSection";
import { isMobile } from "react-device-detect";
import RentItemLayout from "../../layout/RentItemLayout";
import back from "./../../../images/sto-back-mobile.png";
import call from "../../../images/common/phone-call.svg";
import { HeaderLogoImage } from "../../layout/Header";

export const VacancyPage = () => {
  const title = "Вакансии";
  const meta: MetaTags = {
    description: process.env.REACT_APP_WEBSITE_NAME,
    keywords: "leasing,rent,аренда,авто,автомобиль,лизинг,бронирование",
  };
  const [whiteMenu, setWhiteMenu] = useState(false);
  const location = useLocation();

  const activeJobItem =
    mokJobs[Number(location.pathname.split("/").reverse()[0])];

  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile || !activeJobItem) {
      navigate(`/service/job`);
    }
  }, []);

  const [isActive, setActive] = useState<boolean>(false);
  return (
    <>
      <MetaDecorator title={title ?? "Компания Восход"} />
      <RentItemLayout
        whiteMenu={false}
        title={title}
        meta={meta}
        headerType={"white"}
        headerSelectedLink={"/service"}
        noTopPadding={true}
        backFunc={() => navigate(`/service/job`)}
        noFooter
      >
        <S.MainBlock>
          <S.RightColumn>
            {activeJobItem && (
              <S.JobLargeCard id={"job-card"}>
                <S.JobHeader>
                  <p>{activeJobItem.name}</p>
                  {activeJobItem.price}
                </S.JobHeader>
                <S.JobDescriptionColumn>
                  <S.JobDescriptionSection>
                    <p>
                      Требудемый опыт работы:{" "}
                      {activeJobItem.description.work_experience}
                    </p>
                    <p>
                      {" "}
                      <img
                        src={Calendar}
                        alt="Календарь"
                        style={
                          isMobile
                            ? {
                                width: "17px",
                                height: "17px",
                                marginRight: "8px",
                              }
                            : {
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }
                        }
                      />
                      Полная занятость
                    </p>
                    <p>
                      <img
                        src={Shield}
                        alt="Календарь"
                        style={
                          isMobile
                            ? {
                                width: "17px",
                                height: "17px",
                                marginRight: "8px",
                              }
                            : {
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }
                        }
                      />
                      Оформление по ТД РФ
                    </p>
                  </S.JobDescriptionSection>
                  <S.JobDescriptionSection>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      ОБЯЗАННОСТИ:
                    </p>
                    {activeJobItem.description.duties.map((duty, index) => {
                      return (
                        <p key={index}>
                          <img
                            src={Point}
                            alt="Точка"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                          {duty}
                        </p>
                      );
                    })}
                  </S.JobDescriptionSection>
                  <S.JobDescriptionSection>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      ТРЕБОВАНИЯ:
                    </p>
                    {activeJobItem.description.requirements.map(
                      (requirement, index) => {
                        return (
                          <p key={index}>
                            <img
                              src={Point}
                              alt="Точка"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {requirement}
                          </p>
                        );
                      }
                    )}
                  </S.JobDescriptionSection>
                  <S.JobDescriptionSection>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      УСЛОВИЯ:
                    </p>
                    {activeJobItem.description.conditions.map(
                      (condition, index) => {
                        return (
                          <p key={index}>
                            <img
                              src={Point}
                              alt="Точка"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {condition}
                          </p>
                        );
                      }
                    )}
                  </S.JobDescriptionSection>
                  <S.FloatButton>
                    <JobRequestForm
                      text={
                        <span className={"order-call_button"}>
                          Откликнуться
                        </span>
                      }
                      job={activeJobItem}
                      small={true}
                      light={false}
                    />
                  </S.FloatButton>
                </S.JobDescriptionColumn>
              </S.JobLargeCard>
            )}
          </S.RightColumn>
        </S.MainBlock>
      </RentItemLayout>
    </>
  );
};
