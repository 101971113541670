import React from "react";
import { Container } from "react-bootstrap";
import BaseLayout, { BaseLayoutProps } from "./BaseLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import phone from "./../../images/common/Phone-header-dark.svg";
import pointMap from "./../../images/common/footer/contct-page-icons/Geolocation.svg";
import mail from "./../../images/common/footer/social-icons-dark/mail.svg";
import { isMobile } from "react-device-detect";

type OfferLayoutProps = {
  children: any;
};
const OfferLayout: React.FunctionComponent<
  BaseLayoutProps & OfferLayoutProps
> = (props: BaseLayoutProps & OfferLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const back = () => {
    if (location.key !== "default") navigate(-1);
    else navigate("/");
  };
  return (
    <BaseLayout
      {...props}
      headerType={"logo"}
      noHeader={!isMobile}
      backFunc={back}
      noFooter
      noTopPadding
      noSend
    >
      <Container
        fluid={"lg"}
        style={{ width: isMobile ? "100vw" : "940px", padding: "0" }}
      >
        <div
          className={`offer_container ${
            isMobile ? "offer_container-mobile" : "offer_container-desktop"
          }`}
        >
          {props.children}
        </div>

        <div
          className={`offer_footer ${
            isMobile ? "offer_footer-mobile" : "offer_footer-desktop"
          }`}
        >
          <div>
            {isMobile ? (
              <div className="offer_footer-mobile_content">
                <div className="offer_footer_contact">
                  <img src={pointMap} alt="phone" />
                  <Link
                    to="https://yandex.ru/maps/-/CHVQvU8J"
                    className="font-size-12"
                    target="_blank"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    СПб, Торфяная дорога, 7Ф, БЦ «Гулливер» 2
                  </Link>
                </div>
                <div className="offer_footer_contact">
                  <img src={phone} alt="phone" />{" "}
                  <Link
                    to="tel:+78123176815"
                    className="font-size-12"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    +7 (812) 317-68-15
                  </Link>
                </div>
                <div className="offer_footer_contact">
                  <img src={mail} alt="phone" />
                  <Link
                    to="mailto:support@voshod-auto.ru"
                    className="font-size-12"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    support@voshod-auto.ru
                  </Link>
                </div>
                <div
                  className="offer_footer_contact gray"
                  style={{ marginTop: "8px" }}
                >
                  ИНН: 7811532390
                </div>
                <div
                  className="offer_footer_contact gray"
                  style={{ marginBottom: "26px" }}
                >
                  КПП: 781101001
                </div>
                <div className="offer_footer-content">
                  <span className="offer_footer-text">ООО ВОСХОД</span>
                  <Link to={"/policy"} className="offer_footer-link">
                    <p className="offer_footer-text">
                      Политика конфиденциальности
                    </p>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="offer_footer-content">
                <span className="offer_footer-text offer_footer-text-desktop">
                  ООО ВОСХОД
                </span>
                <span className="offer_footer-link">ⓒ 2023 год</span>
                <Link to={"/policy"} className="offer_footer-link">
                  Политика конфиденциальности
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
    </BaseLayout>
  );
};

export default OfferLayout;
