import { useEffect, useState } from "react";
import CarDetailHeader from "../../layout/CarDetailHeader";
import { TypeImages } from "../../pages/Rent/RentCarImagesCarousel";
import "./LargeViewGallery.scss";
import { Carousel } from "react-bootstrap";
import { PhotoSlider } from "react-photo-view";
import prevArrow from "./../../../images/rent/carousel-prev-black.svg";
import nextArrow from "./../../../images/rent/carousel-next-black.svg";
import "react-photo-view/dist/react-photo-view.css";

export const LargeViewGallery = ({
  open,
  onClose,
  images,
  currentIndex,
}: {
  open: boolean;
  onClose: () => void;
  images: TypeImages[];
  currentIndex: number;
}) => {
  const [index, setIndex] = useState<number>(currentIndex);
  const [isViewerOpen, setViewerOpen] = useState(true);

  useEffect(() => {
    if (open) {
      setViewerOpen(true); // Открываем просмотр при монтировании
    }
  }, [open]);

  const slides = images.map((item) => ({
    src: item.image,
    key: item.id,
  }));

  if (!open) return null;

  return (
    <div className="gallery-container">
      <CarDetailHeader type="white" selectedLink="/" backFunc={onClose} />

      <Carousel
        controls={images.length > 1}
        indicators={true}
        activeIndex={index}
        interval={null}
        onSelect={(selectedIndex) => setIndex(selectedIndex)}
        touch={true}
        prevIcon={<img src={prevArrow} alt="Previous" />}
        nextIcon={<img src={nextArrow} alt="Next" />}
      >
        {images.map((item) => (
          <Carousel.Item key={item.id}>
            <div className="carousel-indicator" />
          </Carousel.Item>
        ))}
      </Carousel>
      <PhotoSlider
        images={slides}
        visible={isViewerOpen}
        onClose={() => {
          setViewerOpen(false);
          onClose();
        }}
        className="customSlider"
        index={index}
        onIndexChange={setIndex}
        maskOpacity={0.001}
        bannerVisible={false}
        maskClosable={false}
      />
    </div>
  );
};
