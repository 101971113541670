import styled from "styled-components";
import { DocChapter, DocParagraph } from "../../common/DocPage";

export const OfferHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 171px;
  margin-bottom: 60px;
  p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    line-height: 140%;
  }
  @media (max-width: 575px) {
    display: none;
  }
`;

export const OfferHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
  img.offer_logo {
    height: 44px;
    width: 185px;
  }
  img.phone_call {
    height: 18px;
    width: 18px;
  }
  span {
    color: #606569;
    margin-right: 5px;
    line-height: 140%;
  }
`;

export const OfferHeaderDivider = styled.div`
  width: 100%;
  height: 6px;
  display: flex;
  justify-content: space-between;
  div.red {
    height: 100%;
    width: 80px;
    background-color: #bf3535;
  }
  div.black {
    height: 100%;
    width: 20px;
    background-color: #222222;
  }
  margin-bottom: 45px;
  @media (max-width: 575px) {
    display: none;
  }
`;
export const OfferHeaderDividerMobile = styled.div`
  width: 100%;
  height: 4px;
  display: flex;
  justify-content: space-between;
  div.red {
    height: 100%;
    width: 24px;
    background-color: #bf3535;
  }
  margin-bottom: 24px;
`;

export const OfferTitle = styled.div`
  line-height: normal;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 45px;
  p {
    font-weight: 400;
    color: #606569;
    line-height: 140%;
    margin-bottom: 0;
    font-size: 14px;
  }

  @media (max-width: 575px) {
    font-size: 20px;
    gap: 12px;
    margin-bottom: 16px;
    p {
      font-size: 10px;
      font-style: italic;
      color: #babcbf;
    }
  }
`;

export const DocChapterStyled = styled(DocChapter)``;

export const DocParagraphStyled = styled.p`
  margin-left: 12px;
  margin-bottom: 30px;
  font-size: 14px !important;
  strong {
    font-weight: 700;
  }
  @media (max-width: 575px) {
  margin-left: 16px;
    font-size: 12px !important;
    margin-bottom: 16px;
  }
`;

export const DocParagraphFlatStyled = styled.p`
  margin-bottom: 30px;
  font-size: 14px !important;
  strong {
    font-weight: 700;
  }
  @media (max-width: 575px) {
    font-size: 12px !important;
    margin-bottom: 16px;
  }
`;

export const DocTile = styled.div`
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  margin-bottom: 30px;
  padding-top: 15px;
  @media (max-width: 575px) {
    font-size: 16px !important;
    margin-bottom: 16px;
  }
`;
