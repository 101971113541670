import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  CarBookingStepsType,
  CarbookingSuccess,
  CarRentBookingStatus,
  CarRentChooseDate,
  CarRentChooseeAddress,
  CarRentConfirmPhone,
  CarRentContacts,
  CarRentCreateAccount,
  CarRentFormConfirmed,
  CarRentPaymentType,
  CarRentPaymentTypeConfirm,
} from "./CarRentForm";
import { RentBookingPaymentStatus } from "../../types/RentTypes";
import { ConfirmPaymentQR } from "../../types/AuthContextTypes";
import { ConfirmPhone, ErrorResponse } from "../../Api";
import { useQuery } from "@tanstack/react-query";
import rentService from "../../api-functions/rent-page/rent-service";
import { AxiosError } from "axios";
import LoadError from "./LoadError";
import ModalFormTemplate from "./ModalFormTemplate";
import Loader from "./Loader";
import api from "../../core/axios";

type RentCarDetailModal = {
  paymentStatus: RentBookingPaymentStatus;
  isModalLoading?: boolean;
  setPaymentStatus: (e: RentBookingPaymentStatus) => void;
  step: CarBookingStepsType;
  closeModal: Dispatch<SetStateAction<boolean>>;
  setStep: (e: CarBookingStepsType) => void;
  getPriceCar: () => void;
  depositPrice: number;
  type: "rent" | "taxi";
  setDepositPrice: (e: number) => void;
  carName: string | null;
  setCarName: (e: string) => void;
  errorMessage: null | string;
  isSale: boolean;
  setDates: React.Dispatch<
    React.SetStateAction<{
      StartDate: string;
      EndDate: string | null | undefined;
    }>
  >;
  dates:
    | {
        StartDate: string;
        EndDate: string | null | undefined;
      }
    | undefined;
};

const RentCarDetailModal: FC<RentCarDetailModal> = ({
  paymentStatus,
  setPaymentStatus,
  step,
  setStep,
  getPriceCar,
  depositPrice,
  setDepositPrice,
  carName,
  setCarName,
  closeModal,
  isModalLoading,
  setDates,
  dates,
  type,
  isSale
}) => {
  // id автомобиля из url
  const { carID } = useParams();

  // данные авторизации и профиля
  const { user_status } = useAuth();
  // Сообщение об ошибке
  const [error_message, setErrorMessage] = useState<string | null>(null);
  // Таймер для подтверждения номера телефона
  const [timer, setTimer] = useState(0);
  // Данные для проверки статуса оплаты через QR
  const [confirmPaymentQR, setConfirmPaymentQR] = useState<ConfirmPaymentQR>({
    qr: "",
    pid: "",
  });
  // Состояние для формы подтверждения номера телефона
  const [state, setState] = useState<ConfirmPhone>({
    phone: "",
    confirm: false,
    errors: {},
  });
  // Загрузка данных об авто по id
  const { data, error, isLoading } = useQuery({
    queryKey: [`rent-car-${carID}`, carID],
    queryFn: () => rentService.getOneCar(carID),
  });
  // Подтверждение номера телефона
  const confirmPhone = () => {
    if (user_status === "banned") {
      return;
    }
    api
      .get(`/login.php?auth=1&reg=1&phone=${state.phone}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          setStep("confirm");
          setTimer(res.data.timer ?? 59);
        }
      })
      .catch((e) => {
        setErrorMessage(
          (e as AxiosError<ErrorResponse>).response?.data.message ??
            "Возникла ошибка с сервером поробуйте позже"
        );
      });
  };

  const handleClose = () => {
    closeModal(false);
  };

  if (isLoading) return <Loader />;
  if (error) return <LoadError response={error} />;
  return (
    <ModalFormTemplate
      isLoading={isModalLoading}
      show={true}
      onHide={handleClose}
      centered
      size={"xl"}
    >
      {/* Ввод ноомера телефона */}
      {step === "start" && (
        <CarRentContacts
          error={error_message}
          submit={confirmPhone}
          data={state}
          setData={setState}
          closeOnBack={step == "start"}
          car={data.item}
          closeFunc={handleClose}
          setStep={setStep}
        />
      )}
      {/* Ввод кода подтверждения */}
      {step === "confirm" && (
        <CarRentConfirmPhone
          timer={timer}
          getPriceCar={getPriceCar}
          data={state}
          repeatRequest={confirmPhone}
          car={data.item}
          closeFunc={handleClose}
          setStep={setStep}
        />
      )}
      {/* Имя Фамилия Права */}
      {step === "create" && (
        <CarRentCreateAccount
          getPayment={getPriceCar}
          data={state}
          setData={setState}
          car={data.item}
          closeFunc={handleClose}
          setStep={setStep}
        />
      )}
      {/* Выбор дат */}
      {step === "choose_date" && (
        <CarRentChooseDate
          setConfirmPayment={setConfirmPaymentQR}
          deposit={depositPrice}
          getPriceCar={getPriceCar}
          setDeposit={setDepositPrice}
          data={state}
          car={data.item}
          closeFunc={handleClose}
          setStep={setStep}
          setDates={setDates}
          isSale={isSale}
        />
      )}
      {/* Выбор адреса */}
      {step === "address" && (
        <CarRentChooseeAddress
          data={state}
          setData={setState}
          car={data.item}
          closeFunc={handleClose}
          setStep={setStep}
        />
      )}
      {/* Оплата */}
      {step === "payment" && (
        <CarRentPaymentType
          setConfirmPayment={setConfirmPaymentQR}
          deposit={depositPrice}
          setPaymentStatus={setPaymentStatus}
          setDeposit={setDepositPrice}
          data={state}
          car={data.item}
          dates={dates}
          closeFunc={handleClose}
          setStep={setStep}
          type={type}
        />
      )}
      {step === "confirm_payment" && (
        <CarRentPaymentTypeConfirm
          setCarName={setCarName}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          confirmPayment={confirmPaymentQR}
          deposit={depositPrice}
          setDeposit={setDepositPrice}
          data={state}
          car={data.item}
          closeFunc={handleClose}
          step={step}
          setStep={setStep}
        />
      )}
      {step === "booking_success" && (
        <CarbookingSuccess
          closeFunc={handleClose}
          car={data.item}
          carName={carName}
        />
      )}
      {step === "booking_result" && (
        <CarRentBookingStatus
          paymentStatus={paymentStatus}
          closeFunc={handleClose}
          car={data.item}
          carName={carName}
        />
      )}
      {step === "finish" && <CarRentFormConfirmed closeFunc={handleClose} />}
    </ModalFormTemplate>
  );
};

export default RentCarDetailModal;
