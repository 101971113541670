import styled from "styled-components";

// media.js
const breakpoints = {
  sm: "480px",
  md: "768px",
};

export const media = {
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
};

export const MainBlock = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${media.sm} {
    margin-top: 94px;
    height: 100%;
  }
`;
export const VacancyMainBlock = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${media.sm} {
    margin-top: 94px;
    height: 100%;
  }
`;

export const LeftColumn = styled.div`
  width: 80%;
  padding-bottom: 100px;
  ${media.sm} {
    width: 100vw;
    min-width: 100vw;
    padding-bottom: 52px;
    transform: translateX(-10px);
  }
`;

export const RightColumn = styled.div`
  width: 120%;
  ${media.sm} {
    height: 100%;
  }
`;

export const Header = styled.div`
  padding: 0 0 0 36px;
  border-left: 6px solid #bf3535;
  font-size: 52px;
  margin: 20px 0 50px 0;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;

  ${media.md} {
    font-size: 42px;
    margin: 20px 0 26px 0;
  }

  ${media.sm} {
    font-size: 32px;
    margin: 20px 0 8px 0;
    padding: 0;
    border-left: none;
    margin: 0;
    line-height: 100%;
    padding-bottom: 16px;
    margin-left: 16px;
  }
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.sm} {
    margin-top: 32px;
  }
`;

export const Label = styled.div<{ active: boolean }>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  max-height: 90px;
  box-sizing: border-box;
  padding: 15px 25px 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  max-height: 91px;

  ${media.sm} {
    font-size: 12px;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #f2f3f6;
    trandition: all 0.3s ease;
    svg {
      opacity: 0;
      trandition: all 0.3s ease;
    }
    &:active {
      trandition: all 0.3s ease;
      background-color: rgba(242, 243, 246, 1);
      svg {
        opacity: 1;
      }
    }
  }
  p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
    line-height: normal;
    ${media.sm} {
      margin-bottom: 8px;
      font-size: 20px;
    }
  }
  &:hover {
    background-color: rgba(242, 243, 246, 0.5);
  }
  background-color: ${(props) => (props.active ? "#F2F3F6" : "transparent")};
`;

export const JobLargeCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-top: 30px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 40px 0px #00000014;
  background: #ffffff;

  ${media.sm} {
    padding: 15px;
    padding-top: 0px;

    height: 100%;
    width: 100vw;
    box-shadow: none;
    padding-bottom: 0px;
  }
`;

export const JobHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f3f6;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  p {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  ${media.sm} {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    padding-bottom: 16px;
    p {
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 100%;
      margin-bottom: 8px;
    }
  }
`;

export const JobDescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 40px;
  button {
    margin-top: 10px;
    padding: 15px 25px !important;
    max-height: 49px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  ${media.sm} {
    font-size: 12px;
    padding-bottom: 24px;
    padding-top: 32px;
    gap: 20px;
    button {
      min-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const JobDescriptionSection = styled.div`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    font-size: 16px;
  }
  ${media.sm} {
    p {
      font-size: 12px;
    }
  }
`;

export const FloatButton = styled.div`
  ${media.sm} {
    max-height: 75px;
    width: 198px;
    margin-bottom: 20px;
    width: 100vw;
    display: flex;
    justify-content: center;
    order: 7;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    padding: 12px 16px 24px;
    left: 0;
    background-color: white;
    box-shadow: 0px -5px 32px 0px #0000000d;
    z-index: 700;
    button {
      width: 100%;
      max-width: 100%;
      max-height: 39px;
      margin: 0;
    }
    div {
      width: 100%;
    }
  }
`;

export const JobBackButton = styled.div`
  margin-top: 40px;
  opacity: 0.6;
`;
