import DocumentLayout from "../../layout/DocumentLayout";
import { MetaTags } from "../../layout/BaseLayout";
import MetaDecorator from "../../layout/MetaDecorator";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import * as S from "./OfferPage.styled";
import {
  DocParagraph,
  DocChapter,
  DocParagraphFlat,
} from "./OfferPageComponents";
import logo from "./../../../images/logo-dark.svg";
import phone from "./../../../images/common/Phone-header-dark.svg";
import mail from "./../../../images/common/footer/social-icons-dark/mail.svg";
import OfferLayout from "../../layout/OfferLayout";

const OfferPage = () => {
  const title = "Оферта - " + process.env.REACT_APP_WEBSITE_NAME;
  const meta: MetaTags = {
    description: "Оферта",
    keywords: "Оферта,offer",
  };

  return (
    <>
      <MetaDecorator title={title} url="/policy" />
      <OfferLayout meta={meta} title={title}>
        <S.OfferHeader>
          <S.OfferHeaderColumn>
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="offer_logo"
                style={{ cursor: "pointer" }}
              />
            </Link>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <p>
                <span>ИНН:</span>7811532390
              </p>
              <p>
                <span>КПП:</span>781101001
              </p>
            </div>
          </S.OfferHeaderColumn>
          <S.OfferHeaderColumn>
            <p style={{ fontWeight: "700", textAlign: 'end' }}>
              СПб, Торфяная дорога, 7Ф,
              <br /> БЦ «Гулливер» 2, офис 104
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                alignItems: "flex-end",
              }}
            >
              <Link
                to="tel:+78123176815"
                className="font-size-12"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={phone}
                  alt="logo"
                  className="phone_call"
                  style={{ cursor: "pointer" }}
                />
                <p>+7 (812) 317-68-15</p>
              </Link>
              <Link
                to="mailto:support@voshod-auto.ru"
                className="font-size-12"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={mail}
                  alt="logo"
                  className="phone_call"
                  style={{ cursor: "pointer" }}
                />
                <p style={{ textTransform: "uppercase" }}>
                  support@voshod-auto.ru
                </p>
              </Link>
            </div>
          </S.OfferHeaderColumn>
        </S.OfferHeader>
        <S.OfferHeaderDivider>
          <div className="red"></div>
          <div className="black"></div>
        </S.OfferHeaderDivider>
        <S.OfferTitle>
          Договор публичной оферты
          <br /> проката (аренды) автомобиля
          <p>(без экипажа) и согласие на обработку персональных данных</p>
        </S.OfferTitle>
        {isMobile && (
          <S.OfferHeaderDividerMobile>
            <div className="red"></div>
          </S.OfferHeaderDividerMobile>
        )}
        <DocChapter>
          <DocParagraphFlat>
            Настоящая Публичная Оферта аренды транспортного средства без экипажа
            является предложением Арендодателя заключить публичный договор с
            любым заинтересованным лицом, отвечающим критериям, установленным
            лицом, адресовавшим оферту, на условиях, предусмотренных ниже.
            Настоящий Договор является публичной офертой в соответствии со ст.
            437 Гражданского кодекса РФ, т.е. содержит все существенные условия
            Договора, из которого усматривается воля лица, делающего
            предложение, заключить Договор на указанных условиях с любым лицом,
            совершившим акцепт оферты в соответствии с условиями настоящего
            Договора. Настоящая оферта не является безотзывной и может быть
            отозвана лицом, ее адресовавшим, до момента фактической передачи
            автомобиля Арендатору.
          </DocParagraphFlat>
          <DocParagraphFlat>
            Электронный Договор по юридической силе приравнивается к Договору,
            составленному в письменной форме и подписанному Сторонами, в том
            числе удостоверенному печатями Сторон. Действующая редакция
            настоящего Договора размещается по адресу: <br/><br/>
            <strong>Санкт-Петербург, Торфяная дорога, 7Ф, БЦ «Гулливер» 2</strong>
          </DocParagraphFlat>
          <DocParagraphFlat>
            <strong>Общество с ограниченной ответственностью «ВОСХОД»</strong> в
            лице Генерального директора Свирко Кирилла Андреевича, действующего
            на основании Устава, именуемое в дальнейшем{" "}
            <strong>«Арендодатель»</strong>, с одной стороны, и лицо,
            акцептовавшее условия настоящей оферты, именуемое в дальнейшем{" "}
            <strong>«Арендатор»</strong>, с другой стороны, далее совместно
            именуемые <strong>Стороны</strong>, а по отдельности —{" "}
            <strong>Сторона</strong>, договорились заключить настоящий Договор в
            соответствии со ст. 432–444 Гражданского Кодекса РФ на указанных
            ниже условиях.
          </DocParagraphFlat>
          <DocParagraphFlat>
            Арендатор обязан полностью ознакомиться с условиями настоящего
            Договора. Акцепт настоящего Договора означает полное и
            безоговорочное принятие Арендатором условий настоящего Договора в
            соответствии с нормами действующего законодательства Российской
            Федерации.
          </DocParagraphFlat>
        </DocChapter>
        <DocChapter header="Термины и определения">
          <DocParagraphFlat>
            {" "}
            <strong>Арендатор</strong> — любое дееспособное физическое лицо,
            достигшее 25-летнего возраста, владеющее правом управления
            транспортным средством соответствующей категории, имеющее стаж
            вождения не менее 3 (трех) лет, осуществившее акцепт публичной
            оферты в соответствии с ее условиями.
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>Акцепт</strong> — ответ Арендатора о принятии оферты в виде
            внесения фактической оплаты Арендодателю соответствующего
            бронирования и/или подписания Арендатором акта приема-передачи
            автомобиля.
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>Акт приема-передачи (АПП)</strong> — документ, составляемый
            во время осмотра автомобиля при выдаче клиенту, а также при возврате
            автомобиля от клиента.
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>Автомобиль, транспортное средство (ТС)</strong> —
            транспортное средство, предоставляемое Арендодателем Арендатору во
            временное владение и пользование в рамках настоящего Договора.
            Арендодатель предоставляет автомобиль(и) во временное владение и
            пользование Арендатору от своего имени (в случае, если автомобили
            принадлежат Арендодателю на праве собственности) либо от имени
            собственника автомобиля (в случае, если Арендодатель действует во
            исполнение агентского поручения по передаче транспортных средств во
            временное владение и пользование за плату третьим лицам).
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>
              Нормальное использование/нормальная эксплуатация автомобиля
            </strong>{" "}
            — бережное использование автомобиля с целью передвижения из одной
            точки в другую с должной осмотрительностью, соблюдением Правил
            дорожного движения, а также с учетом метеорологических особенностей,
            особенностей дорожного полотна и т.д., отсутствие движения по
            пересеченной местности, бездорожью, дрифта, агрессивной езды.
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>Ущерб</strong> — любые потери Арендодателя, связанные с
            повреждением, утратой, порчей ТС, прилагаемого имущества или
            документов, включая прямые убытки (стоимость ремонта или замены),
            косвенные убытки (упущенная выгода от простоя ТС) и дополнительные
            расходы (эвакуация, экспертиза, юридические издержки).
          </DocParagraphFlat>
          <DocParagraphFlat>
            {" "}
            <strong>Агрессивное вождение</strong> — управление ТС с превышением
            скорости более чем на 20 км/ч от установленного лимита, резкими
            маневрами, дрифтом, движением по бездорожью или иными действиями,
            увеличивающими износ ТС или риск ДТП, зафиксированное данными
            GPS-мониторинга или иными средствами.
          </DocParagraphFlat>
        </DocChapter>
        <DocChapter header={"1. Предмет Договора"}>
          <DocParagraph prefix={"1.1."}>
            {" "}
            Арендодатель предоставляет Арендатору за обусловленную плату ТС
            согласно характеристикам, указанным в АПП, во временное пользование
            без оказания услуг по управлению и технической эксплуатации ТС, а
            Арендатор принимает ТС и оплачивает арендную плату за его
            использование.
          </DocParagraph>
          <DocParagraph prefix={"1.2."}>
            {" "}
            Перечень автомобилей, их основные характеристики, размер арендной
            платы, тарифы, а также иная информация размещаются на сайте
            Арендодателя и/или в АПП.
          </DocParagraph>
          <DocParagraph prefix={"1.3."}>
            {" "}
            ТС соответствует характеристикам, оснащено комплектом
            дополнительного оборудования и пакетом документов, указанных в АПП.
          </DocParagraph>
          <DocParagraph prefix={"1.4."}>
            {" "}
            Стоимость ТС указана в АПП.
          </DocParagraph>
          <DocParagraph prefix={"1.5."}>
            {" "}
            Сроки и стоимость услуг аренды указаны в АПП.
          </DocParagraph>
          <DocParagraph prefix={"1.6."}>
            {" "}
            Предоставляемое в аренду ТС находится у Арендодателя на основании
            права собственности, договора лизинга либо любого иного правового
            основания, позволяющего Арендодателю пользоваться ТС. Арендодатель
            вправе в одностороннем порядке отказаться от предоставления ТС до
            момента его передачи Арендатору без указания причин и без возмещения
            каких-либо убытков Арендатору.
          </DocParagraph>
          <DocParagraph prefix={"1.7."}>
            {" "}
            Предоставляемое в аренду ТС находится у Арендодателя на основании
            права собственности, договора лизинга либо любого иного правового
            основания, позволяющего Арендодателю пользоваться ТС. Арендодатель
            вправе в одностороннем порядке отказаться от предоставления ТС до
            момента его передачи Арендатору без указания причин и без возмещения
            каких-либо убытков Арендатору.{" "}
          </DocParagraph>
          <DocParagraph prefix={"1.8."}>
            {" "}
            При бронировании Арендатор обязуется предоставить Арендодателю
            фото/копии необходимых документов для бронирования ТС и точные сроки
            аренды с указанием времени и дат.
          </DocParagraph>
          <DocParagraph prefix={"1.9."}>
            {" "}
            Заказ, оформленный Арендатором, носит окончательный характер и
            подлежит автоматизированной обработке в системе бронирования данных
            Арендодателя.{" "}
          </DocParagraph>
          <DocParagraph prefix={"1.10."}>
            {" "}
            Режим работы Арендодателя (выдача/прием ТС) — с 10:00 до 20:00 без
            перерыва и выходных.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"2. Акцепт"}>
          <DocParagraph prefix={"2.1."}>
            {" "}
            Арендатор, желающий заключить Договор, совершает акцепт в
            электронной форме в порядке, установленном настоящим разделом
            Оферты. Договор заключается путем присоединения Арендатора к
            установленным Офертой условиям в целом. Акцепт означает, что
            Арендатор ознакомился со всеми положениями настоящей Оферты,
            согласен с ними и принимает на себя безоговорочное обязательство
            следовать им.
          </DocParagraph>
          <DocParagraph prefix={"2.2."}>
            {" "}
            Арендатор и Арендодатель признают акцептом любое из перечисленных
            действий Арендатора:
            <br /> 2.2.1. Подписание АПП при приеме любого ТС, принадлежащего
            Арендодателю.
            <br /> 2.2.2. Оплата арендной платы.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"3. Условия Договора"}>
          <DocParagraph prefix={"3.1."}>
            {" "}
            Арендодатель предоставляет автомобиль в технически исправном
            состоянии по АПП (Приложение №2).
          </DocParagraph>
          <DocParagraph prefix={"3.2."}>
            {" "}
            Арендатор обязуется по истечении срока действия Договора вернуть ТС
            в технически исправном состоянии, соответствующем отраженному в АПП
            (Приложение №2), о чем подписывается АПП при возврате ТС (Приложение
            №3).
          </DocParagraph>
          <DocParagraph prefix={"3.3."}>
            {" "}
            Арендодатель предоставляет Арендатору ТС в надлежащем техническом
            состоянии, обслуживаемое в соответствии с регламентом производителя.
            При возникновении технических неисправностей арендованного ТС
            возможен обмен на ТС той же ценовой категории при его наличии.
          </DocParagraph>
          <DocParagraph prefix={"3.4."}>
            {" "}
            Арендатор дает безусловную подписку и гарантию Арендодателю, что, не
            уведомив Арендодателя в письменной форме о причине факта невозврата
            ТС через 2 часа по истечении срока аренды (п. 1.3), Арендатор имел
            прямой умысел на хищение ТС при заключении настоящего Договора.
            Вследствие чего его действия классифицируются в соответствии со ст.
            159 УК РФ. В случае просрочки возврата ТС более чем на 2 часа без
            уведомления Арендодателя Арендатор уплачивает штраф в размере 10 000
            руб. за каждый день просрочки, а Арендодатель вправе дистанционно
            заблокировать ТС и/или передать его в розыск без дополнительного
            уведомления.
          </DocParagraph>
          <DocParagraph prefix={"3.5."}>
            {" "}
            Среднесуточный лимит пробега составляет 250 км в сутки. Стоимость
            километров, пройденных свыше данного лимита, указана в Приложении
            №1.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"4. Срок действия Договора"}>
          <DocParagraph prefix={"4.1."}>
            {" "}
            Настоящий Договор вступает в силу с момента акцепта и действует до
            полного исполнения сторонами всех обязательств по настоящему
            Договору. Со всеми условиями Договора Арендатор ознакомляется в
            процессе бронирования. В случае если Арендатору не понятны
            какие-либо условия Договора, в том числе условия отказа, внесения
            любых изменений в оформленный заказ, Арендатор должен уточнить
            необходимую информацию у Арендодателя.
          </DocParagraph>
          <DocParagraph prefix={"4.2."}>
            {" "}
            Настоящий Договор, а также информация об услуге, представленная на
            сайте, являются публичной офертой в соответствии со ст. 435 и ч. 2
            ст. 437 ГК РФ, и данный Договор считается заключенным с момента
            акцепта Арендатором.
          </DocParagraph>
          <DocParagraph prefix={"4.3."}>
            {" "}
            Так как данный Договор является договором публичной оферты,
            осуществляя акцепт полностью и безоговорочно в порядке, определенном
            настоящим Договором, Арендатор подтверждает, что изучил настоящий
            Договор и приложения к нему в полном объеме. Арендатор понимает, что
            акцепт настоящего Договора равносилен заключению письменного
            Договора на условиях, изложенных в Договоре и Приложениях.
          </DocParagraph>
          <DocParagraph prefix={"4.4."}>
            {" "}
            Совершая действия по акцепту Договора, Арендатор гарантирует, что он
            имеет законные права вступать в договорные отношения с
            Арендодателем.
          </DocParagraph>
          <DocParagraph prefix={"4.5."}>
            {" "}
            Срок действия Договора может быть продлен на любой срок соглашением
            сторон, изложенным в письменной форме или средствами электронных
            сообщений (электронная почта, мессенджеры) на контакты, указанные в
            Договоре и утвержденные Арендодателем. Прием-передача ТС не
            оформляется. ТС продолжает находиться во владении Арендатора без
            разрыва по времени.
          </DocParagraph>
          <DocParagraph prefix={"4.6."}>
            {" "}
            По истечении срока действия настоящего Договора, а также в случае
            его досрочного расторжения, Арендатор обязуется вернуть Арендодателю
            автомобиль в технически исправном состоянии, а также дополнительное
            оборудование и комплект документов, необходимых для эксплуатации
            автомобиля, полученные от Арендодателя и соответствующие
            электронному акту приема-передачи автомобиля.
          </DocParagraph>
        </DocChapter>
        <DocChapter
          header={"5. Порядок приема-передачи транспортного средства"}
        >
          <DocParagraph prefix={"5.1."}>
            {" "}
            Передача ТС Арендатору и прием от него производятся по адресу:
            197374, г. Санкт-Петербург, Торфяная дорога, д. 7, лит. Ф, часть
            помещения 1-Н, каб. 3, оф. 104, или проспект Товарищеский, 20/27.
            Передача и/или прием ТС по желанию Арендатора может осуществляться в
            ином месте по стоимости согласно Приложению №1.
          </DocParagraph>
          <DocParagraph prefix={"5.2."}>
            {" "}
            При передаче ТС Арендатору и возврате от Арендатора Стороны с
            помощью ПО Арендодателя составляют АПП, в котором указываются
            регистрационные данные ТС, его комплектация, техническое состояние,
            состояние кузова и салона автомобиля, уровень заправки топливом,
            перечень документов, передаваемых Арендатору с ТС, показания
            одометра на момент передачи и возврата ТС, время и место передачи
            ТС. Передача и возврат ТС сопровождаются обязательной фото- и
            видеофиксацией состояния ТС, проводимой Арендодателем. Подписание
            АПП Арендатором подтверждает отсутствие возражений к
            зафиксированному состоянию ТС. Возражения, заявленные после
            подписания АПП, не принимаются.
          </DocParagraph>
          <DocParagraph prefix={"5.3."}>
            {" "}
            Передача автомобиля Арендатору осуществляется в порядке,
            установленном настоящим Договором, путем заполнения и подписания
            акта приема-передачи (Приложение №2). Факт возврата автомобиля
            Арендодателю оформляется также путем заполнения и подписания АПП при
            возврате (Приложение №3). АПП является обязательным и единственным
            документом, подтверждающим возврат транспортного средства
            Арендатором Арендодателю.
          </DocParagraph>
          <DocParagraph prefix={"5.4."}>
            {" "}
            Передача автомобиля Арендатору осуществляется в порядке,
            установленном настоящим Договором, путем заполнения и подписания
            акта приема-передачи (Приложение №2). Факт возврата автомобиля
            Арендодателю оформляется также путем заполнения и подписания АПП при
            возврате (Приложение №3). АПП является обязательным и единственным
            документом, подтверждающим возврат транспортного средства
            Арендатором Арендодателю.
          </DocParagraph>
          <DocParagraph prefix={"5.5."}>
            {" "}
            Транспортное средство передается Арендатору и возвращается
            Арендодателю с количеством топлива, указанным в АПП. При
            неисполнении Арендатором при возврате ТС указанной обязанности
            недостающее количество топлива оплачивается согласно действующим
            тарифам Арендодателя (Приложение №1).
          </DocParagraph>
          <DocParagraph prefix={"5.6."}>
            {" "}
            По окончании срока аренды Арендатор обязан вернуть ТС в чистом виде
            либо оплатить мойку по тарифу Арендодателя согласно действующим
            тарифам (Приложение №1). Множественные подтеки, следы влаги, разводы
            грязи и химических реагентов, пыльный салон и багажник не попадают
            под критерий чистого автомобиля. Мойка автомобиля оплачивается
            Арендатором при передаче транспортного средства Арендодателю.
          </DocParagraph>
          <DocParagraph prefix={"5.7."}>
            {" "}
            Сдача автомобиля производится по дням: среда, четверг, пятница,
            суббота с 10:00 до 11:00. О сдаче автомобиля необходимо предупредить
            за 7 суток. В день возврата автомобиля аренда за авто не
            списывается. За возврат ТС без предупреждения взимается штраф 10 000
            (десять тысяч) рублей. Автомобиль сдается после мойки и пылесоса.
            Возврат ТС в состоянии, не соответствующем АПП (грязный салон,
            кузов, скрытые повреждения), влечет штраф 5000 руб. и удержание из
            обеспечительного платежа стоимости мойки, ремонта или иных
            восстановительных работ по тарифам Арендодателя.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"6. Порядок и условия расчета арендной платы"}>
          <DocParagraph prefix={"6.1."}>
            {" "}
            Размер арендной платы за арендуемый автомобиль, оценочная стоимость
            автомобиля, а также иные платежи по Договору устанавливаются
            Арендодателем, зависят от марки, комплектации автомобиля и
            указываются в акте приема-передачи (Приложение №2). Иные платежи по
            ремонту, восстановлению ТС, а также поврежденных элементов ТС, утери
            и/или поломки комплектующих устанавливаются Арендодателем в
            Приложении №1.
          </DocParagraph>
          <DocParagraph prefix={"6.2."}>
            {" "}
            При предварительном бронировании гарантией предоставления автомобиля
            является внесение Арендатором задатка (предоплаты), указанного в
            Приложении №2, для обеспечения гарантий по его предоставлению.
            Задаток вносится Арендатором по счету, выставленному Арендодателем.
          </DocParagraph>
          <DocParagraph prefix={"6.3."}>
            {" "}
            В случае отказа от предварительного бронирования задаток
            возвращается, если бронирование отменено за 2 суток до даты аренды
            автомобиля. В случае более позднего отказа от бронирования задаток
            не возвращается.
          </DocParagraph>
          <DocParagraph prefix={"6.4."}>
            {" "}
            В случае изменения сроков аренды Арендодатель оставляет за собой
            право произвести перерасчет арендных платежей исходя из фактического
            срока использования автомобиля в соответствии с действующими
            тарифами и условиями настоящего Договора.
          </DocParagraph>
          <DocParagraph prefix={"6.5."}>
            {" "}
            Оплата по настоящему Договору производится в рублях наличным или
            безналичным расчетом путем перечисления денежных средств на
            расчетный счет Арендодателя, указанный в настоящем Договоре.
          </DocParagraph>
          <DocParagraph prefix={"6.6."}>
            {" "}
            Срок действия Договора указывается в Приложении №2. Договор может
            быть пролонгирован по взаимному согласию сторон путем составления
            дополнительного соглашения к настоящему Договору и/или в результате
            фактического исполнения обязательств сторонами на оговоренный период
            времени.
          </DocParagraph>
          <DocParagraph prefix={"6.7."}>
            {" "}
            Время передачи автомобиля Арендатору, указанное в Приложении №2,
            является расчетным.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.8."}>
            {" "}
            В случае возврата автомобиля по согласованию с Арендодателем с
            превышением данного времени более чем на 4 (четыре) часа арендная
            плата берется за следующие полные сутки. В случае возврата
            автомобиля с превышением расчетного времени, но менее 4 (четырех)
            часов, каждый час тарифицируется согласно Приложению №1.
            Несвоевременным возвратом признается задержка возврата автомобиля
            более чем на 30 минут от расчетного времени, указанного в Приложении
            №2.
          </DocParagraph>
          <DocParagraph prefix={"6.9."}>
            {" "}
            В целях надлежащего исполнения настоящего Договора используется
            гарантия исполнения Договора, именуемая далее Обеспечительный
            платеж, в размере, обозначенном в АПП.
          </DocParagraph>
          <DocParagraph prefix={"6.10."}>
            {" "}
            Обеспечительный платеж вносится в момент акцепта Договора вместе с
            оплатой за весь срок аренды ТС. Возврат Арендатору обеспечительного
            платежа производится наличным или безналичным расчетом на банковскую
            карту Арендатора:
            <br /> 6.10.1. При исполнении Арендатором всех обязательств по
            данному Договору. <br /> 6.10.2. При отсутствии обоюдных претензий,
            в том числе финансовых. <br /> 6.10.3. При отсутствии превышений
            скорости и других признаков агрессивной езды во время пользования
            автомобилем. <br /> 6.10.4. Обеспечительный платеж удерживается
            Арендодателем в полном объеме в случае выявления нарушений условий
            Договора или подозрений на такие нарушения до завершения проверки
            обстоятельств, проводимой Арендодателем.
          </DocParagraph>
          <DocParagraph prefix={"6.11."}>
            {" "}
            Арендатор вносит залоговую сумму (обеспечительный платеж), размер
            которой указан в акте приема-передачи.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.12."}>
            {" "}
            Залоговая сумма (обеспечительный платеж) подлежит возврату через 30
            (тридцать) календарных дней в случае отсутствия нарушений
            действующего Договора.
          </DocParagraph>
          <DocParagraph prefix={"6.13."}>
            {" "}
            При досрочном возврате автомобиля к фактически использованному
            времени прибавляется 2-е суток, после чего происходит перерасчет
            суммы аренды по соответствующему тарифу. Залоговая сумма
            (обеспечительный платеж) в этом случае не возвращается.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.14."}>
            {" "}
            Списание арендной платы происходит ежедневно в 9:00 по московскому
            времени (списывается за текущие сутки). В случае если на 09:00 долг
            по аренде составляет более -500 руб., в 12:00 по московскому времени
            автомобиль блокируется в автоматическом режиме и начисляется штраф
            500 руб. за несвоевременно оплаченную аренду.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.15."}>
            {" "}
            Оплата штрафов любых государственных органов происходит с
            начислением штрафа в размере 50% от суммы начисления.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.16."}>
            {" "}
            Оплата за нарушение условий Договора в части несоблюдения сроков
            возврата автомобиля из аренды; за превышение установленного лимита
            пробега; за повреждения автомобиля, возникшие из-за эксплуатации
            автомобиля или противоправных действий третьих лиц; за упущенную
            выгоду Арендодателя при нарушении сроков возврата автомобиля из
            аренды для предоставления ТС очередному клиенту; за восстановление
            документов и ключей в случае их утери; за утерю инструмента и
            принадлежностей, которыми комплектуется ТС, производится сразу же
            после возврата ТС из аренды согласно оценочной стоимости
            Арендодателя за счет суммы обеспечительного платежа. Если сумма
            обеспечительного платежа недостаточна, то покрывается Арендатором
            внесением дополнительной суммы денежных средств.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.17."}>
            {" "}
            Обеспечительный платеж обращается в пользу Арендодателя
            непосредственно после обнаружения факта причинения ущерба
            Арендодателю в размере стоимости восстановительных работ независимо
            от установления ГИБДД или судом виновности Арендатора, регистрации
            факта ДТП либо отсутствия таковой регистрации, наличия или
            отсутствия самого Арендатора или иного лица, причинившего
            повреждения ТС и т.п. на основании актов приема-передачи ТС.{" "}
          </DocParagraph>
          <DocParagraph prefix={"6.18."}>
            {" "}
            Арендатор не возражает против взыскания суммы убытков, возникших у
            Арендодателя в связи с неисполнением (ненадлежащим исполнением)
            настоящего Договора Арендатором за все время, которое автомобиль не
            эксплуатировался Арендодателем в связи с отсутствием, ремонтом ТС и
            т.п., в бесспорном порядке в размере двойной арендной платы за
            каждый день просрочки и/или простоя.
          </DocParagraph>
          <DocParagraph prefix={"6.19."}>
            {" "}
            При возврате Арендатором ТС ранее окончания срока Договора арендная
            плата возврату не подлежит.
          </DocParagraph>
        </DocChapter>
        <DocChapter
          header={"6А. Привязка платежной карты и безакцептное списание"}
        >
          <DocParagraph prefix={"6А.1"}>
            {" "}
            Арендатор обязуется привязать действующую платежную карту в личном
            кабинете на сайте/мобильном приложении Арендодателя при регистрации
            или перед началом аренды. Привязка платежной карты является
            обязательным условием для заключения настоящего Договора.
          </DocParagraph>
          <DocParagraph prefix={"6А.2."}>
            {" "}
            Арендатор, привязывая платежную карту, дает свое безоговорочное
            согласие на безакцептное (без дополнительного согласия) списание
            Арендодателем с данной платежной карты денежных средств в счет
            оплаты:
            <br />
            6А.2.1. Арендной платы; <br />
            6А.2.2. Обеспечительного платежа; <br />
            6А.2.3. Штрафов ГИБДД и иных административных органов; <br />
            6А.2.4. Штрафов, предусмотренных условиями настоящего Договора;{" "}
            <br />
            6А.2.5. Стоимости ремонта и восстановления автомобиля в случае
            повреждений; <br />
            6А.2.6. Компенсации упущенной выгоды Арендодателя; <br />
            6А.2.7. Стоимости услуг по возврату автомобиля, штрафстоянки; <br />
            6А.2.8. Стоимости топлива, моек, парковок; <br />
            6А.2.9. Иных расходов и платежей, предусмотренных настоящим
            Договором.
            <br />
          </DocParagraph>
          <DocParagraph prefix={"6А.3"}>
            {" "}
            Арендатор обязуется обеспечить наличие достаточной суммы денежных
            средств на привязанной платежной карте для покрытия всех возможных
            расходов в течение всего срока аренды и 30 (тридцати) календарных
            дней после окончания срока аренды.
          </DocParagraph>
          <DocParagraph prefix={"6А.4"}>
            {" "}
            В случае невозможности списания денежных средств с привязанной
            платежной карты по причине недостаточности средств, блокировки
            карты, истечения срока действия карты или по иным причинам Арендатор
            обязуется в течение 24 часов после получения соответствующего
            уведомления от Арендодателя привязать другую действующую платежную
            карту или пополнить баланс имеющейся карты и уведомить об этом
            Арендодателя. В случае уклонения Арендатора от обеспечения списания
            средств (отключение карты, недостаток средств более 48 часов после
            уведомления) Арендатор уплачивает штраф 5000 руб., а Арендодатель
            вправе заблокировать ТС до полного погашения задолженности.
          </DocParagraph>
          <DocParagraph prefix={"6А.5"}>
            {" "}
            В случае несвоевременной оплаты любых платежей по настоящему
            Договору Арендодатель имеет право осуществить попытки списания
            денежных средств с любой из платежных карт, привязанных Арендатором
            в личном кабинете, в том числе с карт, которые были привязаны ранее.
          </DocParagraph>
          <DocParagraph prefix={"6А.6"}>
            {" "}
            При привязке платежной карты Арендодатель вправе произвести
            преавторизацию (холдирование) денежных средств на карте Арендатора в
            целях проверки подлинности и действительности карты, а также наличия
            на ней денежных средств. Сумма преавторизации определяется
            Арендодателем и не может превышать размер обеспечительного платежа.
          </DocParagraph>
          <DocParagraph prefix={"6А.7"}>
            {" "}
            Арендатор соглашается с тем, что Арендодатель вправе производить
            безакцептное списание денежных средств с привязанной платежной карты
            как в период действия Договора, так и после его расторжения в
            течение 180 (ста восьмидесяти) календарных дней с момента окончания
            срока аренды, если основания для такого списания возникли в период
            действия Договора (например, штрафы ГИБДД, выявленные повреждения
            автомобиля и т.п.).
          </DocParagraph>
          <DocParagraph prefix={"6А.8"}>
            {" "}
            Перед каждым безакцептным списанием средств, за исключением
            регулярных списаний арендной платы, Арендодатель направляет
            Арендатору уведомление через личный кабинет, электронную почту или
            SMS с указанием суммы, основания списания и даты планируемого
            списания. Отсутствие возражений со стороны Арендатора в течение 24
            часов после направления такого уведомления считается дополнительным
            подтверждением согласия Арендатора на соответствующее списание.
          </DocParagraph>
          <DocParagraph prefix={"6А.9"}>
            {" "}
            В случае оспаривания Арендатором любого списания, произведенного
            Арендодателем, Арендатор обязуется до обращения в банк или платежную
            систему направить претензию Арендодателю с изложением своих
            возражений. Арендодатель обязуется рассмотреть такую претензию в
            течение 10 (десяти) рабочих дней и предоставить мотивированный
            ответ.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"7. Права и обязанности сторон"}>
          <DocParagraph prefix={"7.1. Арендодатель вправе:"}>
            {" "}
            <br />
            7.1.1. Хранить и обрабатывать предоставленные Арендатором
            персональные данные. <br />
            7.1.2. При наличии достаточных оснований полагать о возможности
            совершения мошеннических действий, а также иных проявлениях
            недобросовестного поведения со стороны Арендатора, и/или отсутствия
            ответа со стороны Арендатора на звонки и SMS, и/или отсутствия
            GPS-сигнала на протяжении более 1 часа, и/или нарушения территории
            допустимого использования, прекратить исполнение по данному
            Договору. Указанные действия квалифицируются сторонами настоящего
            Договора как угон транспортного средства, и Арендодатель вправе в
            одностороннем внесудебном порядке без предварительного письменного
            уведомления Арендатора отказаться от исполнения обязательств по
            настоящему Договору, изъять автомобиль у Арендатора, обратиться с
            заявлением о совершении преступления и привлечении Арендатора к
            ответственности в правоохранительные органы, объявить автомобиль в
            розыск, дистанционно заглушить двигатель, а также прибегнуть к иным
            защитным мерам. Арендодатель вправе дистанционно отключить двигатель
            ТС в случае подозрения на нарушение условий Договора, включая выезд
            за пределы разрешенной территории, агрессивное вождение или
            отсутствие связи с Арендатором более 1 часа.
            <br /> 7.1.3. Размещать на транспортном средстве рекламные материалы
            без согласования с Арендатором. <br />
            7.1.4. Отказаться от исполнения обязательств по настоящему Договору
            и не предоставлять Арендатору ТС во временное владение и пользование
            в случае неисполнения Арендатором обязательства по внесению
            предварительной оплаты при бронировании ТС. <br />
            7.1.5. Отказать Арендатору в возврате предварительной оплаты в
            случае отказа Арендатора от исполнения обязательств по Договору
            (отказа от аренды ТС менее чем за 48 часов до предполагаемой даты
            начала аренды ТС). <br />
            7.1.6. Самостоятельно осуществлять контроль скоростного режима
            автомобиля и начислять штраф в размере 300 (триста) рублей в случае
            3-кратного его нарушения более чем на 20 (двадцать) км/ч
            относительно разрешенной скорости согласно ПДД за каждый
            зафиксированный факт превышения. Штраф за превышение скорости
            начисляется автоматически на основании данных GPS-мониторинга без
            дополнительного уведомления Арендатора. <br />
            7.1.7. Взимать стоимость выезда сотрудника Арендодателя для решения
            технических проблем автомобиля, появившихся по вине Арендатора, а
            также за забор машины со штрафстоянки либо при неоплаченной арендной
            плате, в размере 5000 руб.
          </DocParagraph>
          <DocParagraph prefix={"7.2. Арендодатель обязан:"}>
            {" "}
            <br />
            7.2.1. Предоставить ТС Арендатору в состоянии, соответствующем АПП и
            назначению ТС, со всей относящейся к нему документацией и
            принадлежностями в соответствии с требованиями ГОСТ Р 51709-2001,
            включая полис ОСАГО. <br />
            7.2.2. В присутствии Арендатора проверить исправность сдаваемого в
            аренду ТС, ознакомить Арендатора с основными техническими правилами
            его эксплуатации. <br />
            7.2.3. В случае неисправности ТС, возникшей по вине Арендодателя, а
            равно в случае проведения планового ТО (ремонта), заменить ТС на
            аналогичное или иное по соглашению сторон и произвести перерасчет.
            Замена ТС предоставляется на усмотрение Арендодателя и при наличии
            свободного автомобиля аналогичной категории.
            <br />
            7.2.4. Производить все виды необходимого ремонта ТС и его
            своевременное профилактическое обслуживание своими силами и за свой
            счет, исключая случаи поломки ТС по вине Арендатора. <br />
            7.2.5. Оказывать Арендатору информационную помощь по техническим
            вопросам, связанным с эксплуатацией ТС, а также по вопросам
            поведения в нештатной ситуации, возникшей при использовании ТС.
            <br />
          </DocParagraph>
          <DocParagraph prefix={"7.3. Арендатор обязан:"}>
            {" "}
            <br />
            7.3.1. Использовать ТС в строгом соответствии с его назначением и
            особенностями. При управлении ТС соблюдать ПДД и правила
            эксплуатации ТС. Не управлять ТС в состоянии алкогольного и
            наркотического опьянения. Не использовать ТС для буксировки других
            ТС, поездок с прицепом, поездок по бездорожью, участия в
            соревнованиях, испытаниях, не вводить ТС в намеренный занос, а также
            для обучения вождению.
            <br />
            7.3.2. Не курить в салоне ТС, в том числе запрещается альтернативное
            курение, не открывать бутылки и не употреблять шипучие напитки, не
            употреблять продукты питания, не заниматься интимными половыми
            отношениями, при движении ТС не использовать гаджеты без технологии
            hands-free.
            <br />
            7.3.3. Не перевозить в ТС предметы, относящиеся к
            легковоспламеняющимся и содержащие токсичные вещества, а также иные
            предметы, способные нанести повреждения салону ТС или оставляющие
            стойкие запахи в салоне ТС.
            <br />
            7.3.4. Не размещать рекламу, надписи, рисунки, наклейки внутри или
            снаружи ТС.
            <br />
            7.3.5. Не переуступать свои права и обязанности по Договору третьим
            лицам, не сдавать ТС в субаренду, а также не заключать с третьими
            лицами договоры перевозки, в ходе которых используется арендованное
            ТС. Не передоверять управление ТС третьим лицам. Передача управления
            ТС третьим лицам без письменного согласия Арендодателя влечет штраф
            15 000 руб. и немедленное расторжение Договора.
            <br />
            7.3.6. Эксплуатировать ТС только на дорогах общего пользования,
            имеющих твердое покрытие (асфальт, бетон, мощеная дорога).
            <br />
            7.3.7. Пользоваться ТС в пределах границ Санкт-Петербурга и
            Ленинградской области. Выезд за пределы указанного региона возможен
            только с письменного согласия Арендодателя и за дополнительную
            плату, обозначенную в Приложении №1.
            <br />
            7.3.8. При эксплуатации ТС использовать вид и марку топлива А-95 и
            производить заправку ТС на АЗС «Роснефть», «Лукойл», «Газпромнефть»
            с сохранением чеков о заправке.
            <br />
            7.3.9. Бережно относиться к ТС, проявляя необходимую
            осмотрительность и бережливость для поддержания надлежащего
            технического состояния и внешнего вида ТС.
            <br />
            7.3.10. Не оставлять в ТС регистрационные документы и ключи от замка
            зажигания.
            <br />
            7.3.11. Нести расходы, возникающие в связи с эксплуатацией ТС:
            приобретение топлива, оплата работ по шиномонтажу, мойке автомобиля,
            приобретение жидкости для омывания стекол, плата за парковку ТС,
            перевозка неисправного ТС до СТО Арендодателя, уплата штрафов за
            нарушение ПДД, в том числе за нарушения ПДД, зафиксированные
            автоматическими системами ГИБДД.
            <br />
            7.3.12. Предоставлять Арендодателю ТС для проведения
            профилактического ТО через 30 дней эксплуатации или пробега в 5000
            км с начала срока аренды, в зависимости от того, что наступит ранее.
            <br />
            7.3.13. Проводить ежедневную проверку ТС в соответствии с правилами
            его эксплуатации и незамедлительно сообщать Арендодателю о всех
            обнаруженных неисправностях.
            <br />
            7.3.14. При наступлении случая ДТП принять меры и исполнить
            обязанности, предусмотренные ПДД РФ, а также принять необходимые в
            сложившейся ситуации меры с целью уменьшения возможных убытков от
            происшествия, записать фамилии, телефоны и адреса очевидцев, принять
            меры по оформлению документов о происшествии, а также уведомить
            Арендодателя.
            <br />
            7.3.15. При наступлении иных случаев повреждения, полной утраты или
            уничтожения арендованного ТС, документов, ключей в обязательном
            порядке обратиться в соответствующие организации и получить
            документы, подтверждающие данное событие (полиция, МЧС, ГИБДД и
            т.п.).
            <br />
            7.3.16. Немедленно сообщать обо всех случаях ДТП. После ДТП
            Арендатор обязан предоставить Арендодателю: справку о ДТП в
            установленной законодательством форме, копию протокола, вынесенного
            виновной стороне, копию постановления, вынесенного виновной стороне.
            <br />
            7.3.17. Возместить в полном объеме ущерб, причиненный при
            эксплуатации арендованного ТС Арендатором третьим лицам в случае
            возникновения обязанности владельца ТС возместить вред в части,
            превышающей размер ответственности, предусмотренный Федеральным
            законом «Об обязательном страховании гражданской ответственности
            владельцев транспортных средств». В случае обращения потерпевших в
            суд Арендатор также несет возможные судебные расходы.
            <br />
            7.3.18. Не производить самостоятельно или с привлечением третьих лиц
            ремонт арендованного ТС. Немедленно обратиться к Арендодателю при
            возникновении любого вида неисправности ТС. При невозможности
            продолжить движение принять меры для перевозки ТС на СТО
            Арендодателя.
            <br />
            7.3.19. Незначительные технические неисправности арендованного ТС
            могут быть исправлены Арендатором самостоятельно на СТО только после
            согласования с Арендодателем. Арендодатель компенсирует расходы за
            ремонт при наличии заказ-наряда и кассового чека, оформленных в
            установленном порядке. Компенсационная выплата за ремонт
            производится только в том случае, если поломка произошла не по вине
            Арендатора, согласно нормальному износу узлов ТС. При этом Арендатор
            обязан предоставить вышедшие из строя детали Арендодателю при
            возврате ТС.
            <br />
            7.3.20. Не вправе самовольно производить замену узлов, агрегатов или
            деталей арендованного ТС, кроме ситуации, изложенной в п. 7.3.19.
            <br />
            7.3.21. Не вправе вносить изменения в интерьер и экстерьер ТС, а
            также изменять технические характеристики арендованного автомобиля.
            <br />
            7.3.22. По окончании срока аренды возвратить ТС в том же состоянии и
            комплектации, в которых оно было принято у Арендодателя в
            соответствии с актом приема-передачи ТС, с учетом нормального
            износа.
            <br />
            7.3.23. Пользоваться транспортным средством только в пределах границ
            Санкт-Петербурга и Ленинградской области. Выезд за пределы
            указанного региона возможен только с письменного согласия
            Арендодателя. При несогласованном выезде срабатывает тревожный
            сигнал, происходит блокировка автомобиля, автомобиль передается на
            перехват ГБР.
            <br />
            7.3.24. Во избежание блокировки ТС не оставлять ключ в замке с
            выключенным зажиганием. Машина блокируется в течение 15 секунд.
            <br />
            7.3.25. Курение в салоне автомобиля (в том числе клиентами)
            запрещено. При выявлении факта курения взимается штраф 5000 руб. за
            первое нарушение, каждое последующее — 10 000 руб.
            <br />
            7.3.26. Предоставлять автомобиль для планового осмотра в парк в
            обязательном порядке раз в 10 дней либо по запросу сотрудников
            парка. Машина для осмотра предоставляется в чистом виде. При неявке
            на ТО по записи взимается штраф в размере 1000 руб.
            <br />
            7.3.27. Время, затраченное на шиномонтаж, плановый технический
            осмотр, вызов в офис, получение документов в ГИБДД и прочих
            действий, не компенсируется.
            <br />
            7.3.28. Арендатор обязан по первому требованию Арендодателя сообщить
            точное местоположение ТС в течение 30 минут. Непредоставление
            информации влечет штраф 5000 руб. и блокировку ТС.
          </DocParagraph>
          <DocParagraph prefix={"7.4. Существенные условия"}>
            {" "}
            <br />
            7.4.1. Указанные условия Стороны договорились считать существенными
            условиями Договора.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"8. Ответственность сторон"}>
          <DocParagraph prefix={"8.1."}>
            {" "}
            Арендодатель несет ответственность за исправное техническое
            состояние и комплектацию ТС на момент передачи его Арендатору,
            соответствие документов, прилагаемых к ТС, действующему
            законодательству.
          </DocParagraph>
          <DocParagraph prefix={"8.2."}>
            {" "}
            Арендатор несет ответственность за сохранность ТС, прилагаемого к
            нему имущества и документов в течение всего срока аренды. В случае
            возврата ТС по истечении срока аренды или досрочно в состоянии,
            отличном от того, в каком оно находилось в момент передачи в
            пользование Арендатору, или невозврата вследствие утраты или гибели
            Арендатор возмещает Арендодателю ущерб в полном объеме по тарифам
            Арендодателя. Арендатор несет ответственность за скрытые повреждения
            ТС, выявленные Арендодателем в течение 30 дней после возврата ТС,
            включая износ узлов, не соответствующий нормальной эксплуатации.
          </DocParagraph>
          <DocParagraph prefix={"8.3."}>
            {" "}
            Кроме возмещения понесенного Арендатором ущерба, наступившего в
            результате повреждения или утраты ТС, прилагаемого к нему имущества
            и документов, Арендатор возмещает упущенную выгоду в виде оплаты
            времени вынужденного простоя ТС, необходимого для полного
            восстановления ТС в состояние, в котором оно находилось в момент
            передачи ТС в пользование Арендатору. В случае утраты или полного
            уничтожения ТС временем простоя считается время со дня окончания
            срока аренды по настоящему Договору до дня возмещения Арендатором
            полной стоимости ТС.
          </DocParagraph>
          <DocParagraph prefix={"8.4."}>
            {" "}
            Арендатор несет перед Арендодателем полную материальную
            ответственность за все действия лиц, которых Арендатор допустил к
            вождению арендованного ТС, а также пассажиров, приведших к
            повреждениям или утрате ТС, прилагаемого к нему имущества и
            документов.
          </DocParagraph>
          <DocParagraph prefix={"8.5."}>
            {" "}
            Арендатор самостоятельно и за свой счет несет ответственность за
            вред, причиненный жизни, здоровью и имуществу третьих лиц в период
            эксплуатации арендованного ТС, а также за ущерб, причиненный
            Арендодателю гибелью, повреждением, утратой ТС, предоставленного в
            аренду, в объеме, не покрытом страховым возмещением.
          </DocParagraph>
          <DocParagraph prefix={"8.6."}>
            {" "}
            Арендатор самостоятельно и за свой счет несет ответственность,
            связанную с возможным вредом, причиненным его жизни и здоровью, а
            также жизни и здоровью пассажиров, находящихся в ТС в период
            эксплуатации Арендатором.
          </DocParagraph>
          <DocParagraph prefix={"8.7."}>
            {" "}
            Арендатор самостоятельно и за свой счет несет ответственность за
            возможный ущерб, причиненный багажу, принадлежащему ему или
            пассажирам, находившемуся в ТС в период эксплуатации Арендатором.
          </DocParagraph>
          <DocParagraph prefix={"8.8."}>
            {" "}
            Арендодатель не несет ответственности за действия (бездействия)
            Арендатора, которыми причинен ущерб (вред), в т.ч. третьим лицам. В
            соответствии с настоящим Договором ущерб (вред), причиненный третьим
            лицам (имуществу третьих лиц) Арендатором, возмещается в
            соответствии с законодательством РФ.
          </DocParagraph>
          <DocParagraph prefix={"8.9."}>
            {" "}
            Комплектующие изделия автомобиля не застрахованы. Арендатор несет
            полную материальную ответственность в случае отсутствия, повреждения
            либо иных замечаний к комплектующим изделиям. Материальная
            ответственность Арендатора распространяется также на стекло,
            разбитое при хищении вещей из салона автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"8.10."}>
            {" "}
            Риск случайной утраты (повреждения, порчи) автомобиля несет
            Арендатор с даты передачи автомобиля в аренду и до возврата его
            Арендодателю.
          </DocParagraph>
          <DocParagraph prefix={"8.11."}>
            {" "}
            Арендатор обязан компенсировать Арендодателю стоимость ремонта
            автомобиля согласно заключению экспертизы по оценочной стоимости
            ремонта либо по фактическим затратам Арендодателя, подтвержденным
            заказ-нарядом исполнителя, проводившего ремонт, а также оплатить по
            базовому тарифу аренды автомобиля каждый день простоя автомобиля во
            время его ремонта, в случае если автомобиль поврежден по вине
            Арендатора
          </DocParagraph>
          <DocParagraph prefix={"8.12."}>
            {" "}
            В случае невозврата Арендатором автомобиля по первому требованию
            Арендодателя Арендодатель обращается в органы МВД РФ с заявлением по
            факту хищения автомобиля (что соответствует ст. 158 УК РФ).
            Арендодатель имеет право потребовать от Арендатора выплаты неустойки
            в размере десятикратной стоимости суточной аренды автомобиля за
            каждый день просрочки. Допускается (при наступлении чрезвычайных
            обстоятельств) задержка автомобиля Арендатором, но не более чем на 2
            часа (при условии уведомления Арендатором Арендодателя об этом по
            телефону).
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"9. Страхование и ответственность за ущерб"}>
          <DocParagraph prefix={"9.1."}>
            {" "}
            Арендатор обязан за свой счет осуществлять страхование гражданской
            ответственности (ОСАГО).
          </DocParagraph>
          <DocParagraph prefix={"9.2."}>
            {" "}
            В случае повреждения автомобиля Арендатор обязуется немедленно
            сообщить об этом Арендодателю, вызвать представителей ГИБДД или МВД.
          </DocParagraph>
          <DocParagraph prefix={"9.3."}>
            {" "}
            В случае невозможности взыскания компенсации повреждения автомобиля
            с виновных Арендатор обязуется компенсировать стоимость ремонта
            автомобиля в размере 100%.
          </DocParagraph>
          <DocParagraph prefix={"9.4."}>
            {" "}
            В случае повреждения автомобиля по вине Арендатора Арендатор обязан
            возместить Арендодателю убытки в виде упущенной выгоды из расчета
            суточной стоимости аренды за каждый день вынужденного простоя
            автомобиля, находящегося в ремонте. Арендная плата и обеспечительный
            платеж в этом случае возврату не подлежат.
          </DocParagraph>
          <DocParagraph prefix={"9.5."}>
            {" "}
            Арендатор обязан в случае повреждения в результате ДТП получить и не
            позднее 5 календарных дней предоставить Арендодателю: <br />
            9.5.1. Справку ГИБДД (ОВД) с указанием сведений о
            водителях-участниках ДТП; <br />
            9.5.2. Заверенную копию схемы ДТП; <br />
            9.5.3. Заверенную копию Постановления о привлечении к
            административной ответственности либо заверенную копию Определения
            об отказе в возбуждении дела об административном правонарушении.
            <br />
            9.5.4. Непредоставление указанных документов в течение 5 календарных
            дней влечет обязанность Арендатора компенсировать Арендодателю
            полный ущерб, независимо от установления виновных лиц.
            <br />
          </DocParagraph>
          <DocParagraph prefix={"9.6."}>
            {" "}
            В случае повреждения в результате взрыва, пожара — получить и не
            позднее 5 календарных дней предоставить Арендодателю справку
            инспекции Госпожарнадзора (органов МЧС), копию постановлений
            предварительного следствия. Непредоставление указанных документов в
            течение 5 календарных дней влечет обязанность Арендатора
            компенсировать Арендодателю полный ущерб, независимо от установления
            виновных лиц.
          </DocParagraph>
          <DocParagraph prefix={"9.7."}>
            {" "}
            В случае повреждения в результате стихийного бедствия — получить и
            не позднее 5 календарных дней предоставить Арендодателю справку
            Гидрометеорологической службы либо иного государственного
            компетентного органа. Непредоставление указанных документов в
            течение 5 календарных дней влечет обязанность Арендатора
            компенсировать Арендодателю полный ущерб, независимо от установления
            виновных лиц.
          </DocParagraph>
          <DocParagraph prefix={"9.8."}>
            {" "}
            В случае утраты при любых обстоятельствах автомобиля (угон, хищение)
            Арендатор обязуется:
            <br />
            9.8.1. Незамедлительно сообщить об этом Арендодателю; <br />
            9.8.2. Немедленно по телефону сообщить в ближайшее отделение МВД о
            факте угона, хищения с дальнейшим оформлением письменного заявления;{" "}
            <br />
            9.8.3. В кратчайшее время (не более двух календарных дней)
            предоставить Арендодателю свидетельство о регистрации автомобиля и
            комплект ключей; <br />
            9.8.4. Не позднее 5 календарных дней предоставить копию
            постановления о возбуждении уголовного дела. <br />
            9.8.5. Непредоставление указанных документов в течение 5 календарных
            дней влечет обязанность Арендатора компенсировать Арендодателю
            полный ущерб, независимо от установления виновных лиц.
            <br />
          </DocParagraph>
          <DocParagraph prefix={"9.9."}>
            Арендатор несет персональную ответственность за все действия,
            совершенные Арендатором при аренде автомобиля с участием этого
            автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"9.10."}>
            Арендатор обязан за свой счет оформить страховой полис КАСКО на весь
            срок аренды с указанием Арендодателя как выгодоприобретателя.
            Непредоставление полиса в течение 24 часов с момента передачи ТС
            влечет штраф 20 000 руб. и расторжение Договора.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"10. Основания и порядок расторжения Договора"}>
          <DocParagraph prefix={"10.1."}>
            {" "}
            Арендодатель вправе расторгнуть Договор в одностороннем порядке в
            случаях:
            <br />
            10.1.1. Систематического (10 и более раз) неисполнения
            (ненадлежащего исполнения) Арендатором обязанностей, предусмотренных
            любым из пунктов Договора.
            <br />
            10.1.2. Если Арендатор пользуется транспортным средством с
            существенным нарушением условий Договора или назначения
            транспортного средства.
            <br />
            10.1.3. Если Арендатор существенно ухудшает транспортное средство.
            <br />
            10.1.4. Если Арендатор допустил просрочку по оплате аренды более 10
            (десяти) календарных дней.
            <br />
            10.1.5. Если Арендатор сдал транспортное средство в субаренду, или
            передал свои права и обязанности по Договору другому лицу
            (перенаем), или предоставил транспортное средство в безвозмездное
            пользование, или отдал арендные права в залог, или внес их в
            качестве вклада в уставный капитал хозяйственных товариществ и
            обществ или паевого взноса в производственный кооператив, или
            совершил отчуждение транспортного средства иным образом без
            предварительного письменного согласия Арендодателя.
            <br />
            10.1.6. Если после соответствующего уведомления о поступлении
            транспортного средства на склад Арендодателя Арендатор не забрал ТС
            в указанный срок. Первоначальный взнос в этом случае не
            возвращается.
            <br />
            10.1.7. Нарушения Арендатором условий Договора, которые очевидно
            приводят к необходимости ремонта или иных действий для
            восстановления исходного состояния ТС, и стоимость необходимых работ
            превышает величину обеспечительного платежа.
            <br />
            10.1.8. Если Арендатор не предоставляет ТС для проведения планового
            ТО в срок, установленный настоящим Договором в п. 7.3.12.
            <br />
            10.1.9. Если, несмотря на предупреждения Арендодателя, Арендатор
            продолжает нарушать условия настоящего Договора и создает угрозу
            повреждения или утраты ТС, прилагаемого к нему имущества и
            документов.
            <br />
            10.1.10. Если Арендатор систематически нарушает ПДД РФ.
            <br />
            10.1.11. Арендодатель вправе расторгнуть Договор в одностороннем
            порядке немедленно в случае возникновения угрозы утраты ТС (угон,
            выезд за пределы региона без согласования, отсутствие связи с
            Арендатором более 12 часов).
          </DocParagraph>
          <DocParagraph prefix={"10.2."}>
            {" "}
            Арендатор вправе: <br />
            10.2.1. Расторгнуть Договор в любое время, уведомив Арендодателя не
            менее чем за 1 сутки. При возврате Арендатором ТС ранее окончания
            срока Договора арендная плата возврату не подлежит.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"11. Порядок изменения и расторжения Договора"}>
          <DocParagraph prefix={"11.1."}>
            {" "}
            Арендодатель вправе в одностороннем порядке вносить изменения в
            редакцию настоящего Договора, уведомив об этом Арендатора путем
            публикации новой версии Договора на сайте Арендодателя. Оферта
            настоящего Договора является актуальной с момента публикации его на
            сайте Арендодателя и действует неограниченное количество времени.
            Арендатор самостоятельно несет риск неполучения и/или неознакомления
            с указанным уведомлением. В случае если в течение 3 (трех) дней с
            момента публикации новой версии Договора Арендатором не было
            выражено отказа от изменения условий Договора, условия Договора
            считаются измененными.
          </DocParagraph>
          <DocParagraph prefix={"11.2."}>
            {" "}
            Настоящий Договор может быть досрочно прекращен или продлен по
            соглашению сторон.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"12. Согласие на обработку персональных данных"}>
          <DocParagraph prefix={"12.1."}>
            {" "}
            Арендатор дает свое согласие на обработку в ООО «ВОСХОД» своих
            персональных данных, относящихся исключительно к перечисленным ниже
            категориям персональных данных: фамилия, имя, отчество; пол; дата
            рождения; тип документа, удостоверяющего личность; данные документа,
            удостоверяющего личность; гражданство.
          </DocParagraph>
          <DocParagraph prefix={"12.2."}>
            {" "}
            Арендатор дает согласие на использование персональных данных
            исключительно в целях проверки службой безопасности, а также на
            хранение данных об этих результатах на электронных носителях.
          </DocParagraph>
          <DocParagraph prefix={"12.3."}>
            {" "}
            Настоящее согласие предоставляется на осуществление действий в
            отношении персональных данных, которые необходимы для достижения
            указанных выше целей, включая (без ограничения) сбор,
            систематизацию, накопление, хранение, уточнение (обновление,
            изменение), использование, передачу третьим лицам для осуществления
            действий по обмену информацией, обезличивание, блокирование
            персональных данных, а также осуществление любых иных действий,
            предусмотренных действующим законодательством Российской Федерации.
          </DocParagraph>
          <DocParagraph prefix={"12.4."}>
            {" "}
            Арендатор проинформирован, что ООО «ВОСХОД» гарантирует обработку
            персональных данных в соответствии с действующим законодательством
            Российской Федерации как неавтоматизированным, так и
            автоматизированным способами.
          </DocParagraph>
          <DocParagraph prefix={"12.5."}>
            {" "}
            Данное согласие действует до достижения целей обработки персональных
            данных или в течение срока хранения информации.
          </DocParagraph>
          <DocParagraph prefix={"12.6."}>
            {" "}
            Данное согласие может быть отозвано в любой момент по письменному
            заявлению Арендатора.
          </DocParagraph>
          <DocParagraph prefix={"12.7."}>
            {" "}
            Арендатор подтверждает, что, давая такое согласие, действует по
            собственной воле и в своих интересах.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"13. Электронные доказательства"}>
          <DocParagraph prefix={"13.1."}>
            {" "}
            Арендатор признает юридическую силу данных систем GPS-мониторинга,
            установленных на автомобиле, как доказательство в случае нарушения
            условий настоящего Договора, включая нарушение скоростного режима,
            выезд за пределы разрешенной территории использования, агрессивное
            вождение и другие нарушения. Юридическую силу имеют также данные
            телеметрии ТС (уровень топлива, нагрузка на двигатель) и показания
            свидетелей, зафиксированные Арендодателем.
          </DocParagraph>
          <DocParagraph prefix={"13.2."}>
            {" "}
            Стороны признают юридическую силу фото- и видеофиксации состояния
            автомобиля при приеме-передаче, а также фиксации повреждений,
            обнаруженных Арендодателем после возврата автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"13.3."}>
            {" "}
            В случае нарушения ПДД, зафиксированного автоматическими системами
            ГИБДД, Арендатор признает штраф, о чем Арендодатель ставит в
            известность Управление ГИБДД МВД по г. Санкт-Петербургу и
            Ленинградской области путем предоставления заявления от Арендатора о
            признании нарушений.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"14. Заключительные условия"}>
          <DocParagraph prefix={"14.1."}>
            {" "}
            Все уведомления и документация, связанные с исполнением обязательств
            по Договору, считаются надлежащим образом направленными и
            доставленными одной из Сторон, если они направляются на электронную
            почту, SMS-сообщениями или сообщениями в мессенджерах. Арендодатель
            вправе уведомлять Арендатора об изменении адресов электронной почты,
            контактов в мессенджерах и реквизитов путем публикации новой
            информации на сайте.
          </DocParagraph>
          <DocParagraph prefix={"14.2."}>
            {" "}
            Стороны признают юридическую силу переписки, сообщений, документов,
            направленных на электронную почту и/или размещенных на сайте
            Арендодателя.
          </DocParagraph>
          <DocParagraph prefix={"14.3."}>
            {" "}
            Стороны несут ответственность за точность своих почтовых адресов и
            контактных данных, указанных при бронировании ТС.
          </DocParagraph>
          <DocParagraph prefix={"14.4."}>
            {" "}
            В случае изменения почтового адреса, адреса электронной почты или
            каких-либо иных реквизитов, указанных в настоящем Договоре,
            Арендодатель публикует новые реквизиты на сайте, а Арендатор
            направляет уведомление в адрес Арендодателя. Сторона обязана
            немедленно направить другой Стороне соответствующее уведомление с
            указанием нового адреса. При невыполнении этой обязанности вся
            корреспонденция, направленная по прежнему адресу, считается
            надлежащим образом полученной.
          </DocParagraph>
          <DocParagraph prefix={"14.5."}>
            {" "}
            Арендатор, подписывая данный Договор, дает свое согласие на
            обработку данных на усмотрение Арендодателя.
          </DocParagraph>
          <DocParagraph prefix={"14.6."}>
            {" "}
            В части, не урегулированной настоящим Договором, Стороны
            руководствуются действующим законодательством Российской Федерации.
          </DocParagraph>
          <DocParagraph prefix={"14.7."}>
            {" "}
            В случае возникновения споров Стороны будут стремиться к разрешению
            их путем переговоров. При невозможности урегулирования споров путем
            переговоров споры разрешаются в суде по месту нахождения
            Арендодателя. Перед обращением в суд Арендатор обязан направить
            Арендодателю письменную претензию и дождаться ответа в течение 15
            рабочих дней. Нарушение данного порядка влечет отказ в иске.
          </DocParagraph>
          <DocParagraph prefix={"14.8."}>
            {" "}
            Арендодатель не несет ответственности за неисполнение обязательств
            по Договору в случае форс-мажорных обстоятельств (стихийные
            бедствия, действия государственных органов, технические сбои), при
            этом обязательства Арендатора сохраняются в полном объеме.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"15. Адреса и реквизиты оферента"}>
          <div
            style={{
              fontSize: isMobile ? "12px" : "14px",
              marginLeft: isMobile ? "16px" : "12px",
              lineHeight: "200%",
            }}
          >
            ООО «Восход» <br></br>
            ОГРН/ИНН: 1127847512430/7811532390 <br />
            КПП: 781101001 <br />
            Адрес: 197374, город Санкт-Петербург, Торфяная дор., д. 7, лит. Ф,
            часть помещения 1-Н, кабинет 3, офис 104
            <br />
            Банковские реквизиты:
            <br />
            Р/с №: 40702810010000084656 <br />
            Банк: АО "Тинькофф Банк"
            <br />
            К/с №: 30101810145250000974
            <br />
            БИК: 044525974
            <br />
            Телефон: 8 (812) 317-68-15
            <br />
            Генеральный директор: Свирко К.А.
            <br />
          </div>
        </DocChapter>
      </OfferLayout>
    </>
  );
};

export default OfferPage;
