import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../../../../utils/Utils";
import { CodeConfirmForm } from "../../../../pages/PersonalAccount/PersonalAccountModal/PersonalAccountModal";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { ModalTemplateInput } from "../../../ModalFormTemplate";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import balanceService from "../../../../../api-functions/balance/balance-service";
import sbplogo from "../../../../../images/personal-account/transactions/sbplogo.png";
import card from "../../../../../images/personal-account/transactions/card.png";
import testQR from "../../../../../images/personal-account/transactions/testQR.png";
import { cardsData } from "../../PersonalAccountWithdraw/mobile/PersonalAccountWithdraw";
import CardSelect from "../../../CardSelect/CardSelect";
import AccountSelect from "../../../AccountSelect/AccountSelect";
import api from "../../../../../core/axios";
import { runErrorToast, runSuccessToast } from "../../../AppToast/AppToast";
import { isMobile } from "react-device-detect";

const PersonalAccountReplenish: React.FC<{
  onHide: () => void;
  getCode: (item: string, setError: (err: string) => void) => void;
  step: string;
  setStep: (arg0: string) => void;
  currentPhone: string;
}> = (props) => {
  const { onHide, getCode, step, setStep, currentPhone } = props;

  const [payload, setPayload] = useState({
    sum: 0,
    type: "sbp",
    from: {
      name: cardsData.cards[0].name,
      number: cardsData.cards[0].number,
    },
    to: {
      name: "",
      icon: "",
      balance: 0,
    },
    errors: {},
    axiosError: "",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["accounts"],
    queryFn: () => balanceService.getBalance(),
  });

  const [payToUnlockValid, setPayToUnlockValid] = useState<boolean | undefined>(
    undefined
  );

  const setFromValue = (value: { name: string; number: string }) => {
    let errors = payload.errors;
    delete errors["from"];
    let newData = {
      ...payload,
      from: {
        name: value.name,
        number: value.number,
      },
      errors: errors,
    };
    setPayload(newData);
    // errors = Utils.validateWithdraw(payload, data);
  };

  const setToValue = (value: {
    name: string;
    icon: string;
    balance: number;
  }) => {
    let errors = payload.errors;
    delete errors["to"];
    let newData = {
      ...payload,
      to: {
        name: value.name,
        icon: value.icon,
        balance: value.balance,
      },
    };
    setPayload(newData);
  };

  const validateData = (field: string, value: string) => {
    if (data && data.unblock && data.unblock.sum) {
      setPayToUnlockValid(Number(value) >= data.unblock.sum);
    }
    let errors = payload.errors;
    delete errors[field];
    delete errors["server"];
    let newData = { ...payload, [field]: value, errors: errors };
    setPayload(newData);
    // errors = Utils.validateWithdraw(data, balance);
  };

  const setPaymentType = (type: string) => {
    let errors = payload.errors;
    delete errors["server"];
    setPayload((prev) => ({ ...prev, type: type }));
    if (type === "card") {
      setPayload((prev) => ({
        ...prev,
        from: cardsData.cards[0],
        errors: errors,
      }));
    }
    // else {
    //     setPayload(prev => ({
    //         ...prev, from: {
    //             name: "",
    //             number: ""
    //         }
    //     }));
    // }
  };

  const setServerError = (error: string) => {
    let errors = payload.errors;
    delete errors["server"];

    setPayload((prev) => ({
      ...prev,
      errors: {
        ...errors,
        server: error,
      },
    }));
  };

  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
  const [qrUrl, setQrUrl] = useState();

  interface AxiosError {
    code: string;
    response: {
      data: {
        result: number;
        message: string;
      };
    };
  }

  const send = async () => {
    let errors = Utils.validateReplenish(payload);
    if (Object.keys(errors).length > 0) {
      setPayload({ ...payload, errors: errors });
      return;
    }
    try {
      // сумма с комиссией
      const res = await api.get(
        `/voshod-auto/?w=balance_up&summ=${payload.sum}&payment=${
          payload.type === "card" ? "classic" : "sbp"
        }&device=${isMobile ? "mobile" : "desktop"}`,
        {
          withCredentials: true,
        }
      );
      // если все ок при оплате картой
      if (res.data.result === 1 && payload.type === "card") {
        // Если пользователь использует Safari
        window.location.assign(res.data.redirect);
        return;
      }
      // если все ок при сбп
      if (res.data.result === 1 && payload.type === "sbp") {
        // ставим url для qr кода
        setQrUrl(res.data.qr);
        // открываем qr
        setIsQrOpen(true);
        // каждые две секунды проверяем оплату по pid
        const checkPayment = setInterval(async () => {
          const token = localStorage.getItem("accessToken");
          const check = await api.get(
            `/voshod-auto/?w=balance_check&pid=${res.data.pid}`,
            {
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
              withCredentials: true,
            }
          );
          if (check.data.data.status === "CONFIRMED") {
            console.log("CONFIRMED");
            runSuccessToast({
              firstMessage: <span>Операция прошла успешно!</span>,
              secondMessage: (
                <span>
                  +{payload.sum}₽ {">"} {check.data.account_to_name}
                </span>
              ),
              toastId: payload.sum.toString(),
            });
            clearInterval(checkPayment);
            onHide();
            return;
          }
          if (check.data.data.status === "CANCELED") {
            console.log("CANCELED");
            runErrorToast({
              firstMessage: <span>Ошибка при проведении операции!</span>,
              secondMessage: (
                <span>
                  <s>+{payload.sum}₽</s>Платёж отменён
                </span>
              ),
              toastId: payload.sum.toString(),
            });
            clearInterval(checkPayment);
            onHide();
            return;
          }
        }, 2000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError!.response.data.message);
      setPayload({ ...payload, axiosError: axiosError!.response.data.message });
    }
  };

  return (
    <>
      {step === "new" && (
        <>
          <div className={"mb-px-60"}>
            <button
              className={
                "default-link font-size-18 font-weight-semibold text-hover-default"
              }
              onClick={() => (isQrOpen ? setIsQrOpen(false) : onHide)}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              &nbsp;&nbsp;ВЕРНУТЬСЯ
            </button>
          </div>
          {isQrOpen && qrUrl ? (
            <img
              src={`data:image/svg+xml;utf8,${encodeURIComponent(qrUrl)}`}
              alt="qr"
              width={200}
              height={200}
            />
          ) : (
            <>
              <div className="mb-px-40">
                <div
                  className={
                    "call-content-text-header font-size-40 mb-px-10 line-height-130 font-weight-semibold"
                  }
                >
                  Пополнить счёт
                </div>
                <div className={"call-content-text font-size-16 fw-medium"}>
                  Выберите способ оплаты и&nbsp;счёт,
                  <br />
                  и&nbsp;введите необходимую сумму для пополнения
                </div>
              </div>
              <div className="mb-px-10">
                <ModalTemplateInput
                  error={payload.errors["sum"]}
                  type={"number"}
                  small={false}
                  placeholder="Введите сумму"
                  onChange={(e: any) => validateData("sum", e.target.value)}
                />
                {payload.errors["server"] && (
                  <div className={"my-2 text-red-color font-size-12"}>
                    {payload.errors["server"]}
                  </div>
                )}
              </div>
              {data && data.unblock && data.unblock.text && (
                <div
                  className={
                    "replenish_unlock-conditions" +
                    (payToUnlockValid !== undefined
                      ? payToUnlockValid
                        ? "_green"
                        : "_red"
                      : "")
                  }
                >
                  {data.unblock.text}
                </div>
              )}
              <div className="mb-px-20">
                <div className="replenish_types">
                  <div
                    className={
                      "replenish_type " +
                      (payload.type === "sbp" ? "active" : "")
                    }
                    onClick={() => setPaymentType("sbp")}
                  >
                    <img src={sbplogo} alt="СБП" className="replenish_icon" />
                    <span>СБП</span>
                  </div>
                  <div
                    className={
                      "replenish_type " +
                      (payload.type === "card" ? "active" : "")
                    }
                    onClick={() => setPaymentType("card")}
                  >
                    <img src={card} alt="card" className="replenish_icon" />
                    <span>БАНКОВСКАЯ КАРТА</span>
                  </div>
                </div>
              </div>
              {/* {payload.type === "card" && (
            <div>
              <CardSelect
                cards={cardsData.cards}
                accounts={cardsData.accounts}
                onSelect={setFromValue}
                error={payload.errors["from"]}
                placeholder="Выбрать другую карту или счёт"
              />
            </div>
          )} */}
              {/* <div>
                        <AccountSelect data={data && data.accounts} error={payload.errors["to"]} placeholder="Выберите счёт" onSelect={setToValue} />
                    </div> */}
              <div className="mt-auto d-flex align-items-start justify-content-between flex-column">
                {payload.sum > 0 && payload.sum && (
                  <span className="font-size-14 font-weight-semibold mb-px-20 mt-px-30">
                    К оплате:{" "}
                    {Utils.formatNumber(
                      payload.type === "card"
                        ? payload.sum / 0.97
                        : payload.sum / 0.99
                    )}{" "}
                    ₽
                  </span>
                )}
                <div className="d-flex justify-content-between">
                  <button
                    className={"site-btn small dark"}
                    onClick={(e) => {
                      e.preventDefault();
                      send();
                    }}
                  >
                    Далее
                  </button>
                  <span className="my-2 text-red-color font-size-12 text-end w-45">
                    {payload.axiosError}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {/* {step === "confirm" && (
                <CodeConfirmForm
                    step={step}
                    setStep={setStep}
                    repeatRequest={getCode}
                    newPhone={''}
                    currentPhone={Utils.formatPhone(currentPhone)}
                    type={"replenish"}
                    onHide={onHide}
                />
            )} */}
    </>
  );
};

export default PersonalAccountReplenish;
