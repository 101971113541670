import { Container, Row } from "react-bootstrap";
import * as S from "./Mainection.styled";
import CustomBackButton from "../../../common/CustomBackButton/CustomBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import Calendar from "./../../../../images/common/calendar-red.svg";
import Shield from "./../../../../images/common/shield-red.svg";
import Point from "./../../../../images/common/square-red.svg";
import JobRequestForm from "../JobForm";
import { useNavigate } from "react-router-dom";

export const mokJobs = [
  {
    name: "Автомаляр",
    id: 1,
    price: "от 80 000 ₽",
    description: {
      work_experience: "1 - 3 года",
      duties: [
        "Выполнение восстановительных работ по лакокрасочным покрытиям и антикоррозионной защите элементов кузова.",
        "Подготовка поверхности авто под покраску.",
        "Покраска автомобиля, полировка.",
      ],
      requirements: [
        "Опыт работы на аналогичной позиции от 1 года.",
        "Знание технологии окраски автомобиля.",
        "Качество выполняемых работ",
        "Качество и быстрота выполняемых работ.",
      ],
      conditions: [
        "Дружный коллектив",
        "Работа в стабильной компании",
        "График работы оговаривается на собеседовании",
        "Профессиональный инструмент и оборудование",
      ],
    },
  },
  {
    name: "Автомеханик-автослесарь",
    id: 2,
    price: "от 100 000 ₽",
    description: {
      work_experience: "1 - 3 года",
      duties: [
        "Диагностика, обслуживание и ремонт автомобилей.",
        "Поддержание порядка на рабочем месте.",
        "Соблюдения правил техники безопасности.",
      ],
      requirements: [
        "Профильное образование приветствуется.",
        "Опыт работы не менее года в должности (автомеханик, автослесарь).",
        "Знание устройства автомобиля и технологии его ремонта.",
        "Дисциплина, пунктуальность.",
        "Знание шиномонтажа будет Вашим дополнительным ресурсом.",
      ],
      conditions: [
        "Дружный коллектив",
        "Работа в стабильной компании",
        "График работы оговаривается на собеседовании",
        "Профессиональный инструмент и оборудование",
        "Честная зарплата без задержек, высокий процент.",
      ],
    },
  },
  {
    name: "Кузовщик",
    id: 3,
    price: "от 90 000 ₽",
    description: {
      work_experience: "1 - 3 года",
      duties: [
        "Выполнение работ по восстановительному кузовному ремонту легковых автомобилей.",
        "Выполнение разборки сборки автомобиля, вклейка стекол.",
        "Восстановление геометрии кузова аварийных ТС.",
        "Арматурные работы.",
      ],
      requirements: [
        "Выполнение поставленных задач.",
        "Аккуратность.",
        "Ответственность.",
        "Дисциплина, пунктуальность.",
        "Опыт работы кузовщиком от года.",
      ],
      conditions: [
        "Заработная плата сдельная.",
        "Работа в стабильной компании.",
        "Выплаты дважды в месяц.",
      ],
    },
  },
  {
    name: "Автоэлектрик",
    id: 4,
    price: "100 000 ₽ – 150 000 ₽",
    description: {
      work_experience: "1 - 3 года",
      duties: [
        "Поиск и устранение неисправностей в системах автомобилей.",
        "Чтение электросхем, ремонт блоков управления.",
        "Восстановление геометрии кузова аварийных ТС.",
        "Установка дополнительного оборудования: сигнализации, камеры, аудио-видео, парктроники и т.д.",
      ],
      requirements: [
        "Опыт работы диагностом-электриком на автосервисах или у официальных диллеров со сканерами мультибренд обязателен.",
        "Умение работать с диагностическим оборудованием (Autel, Launch).",
        "Ответственность.",
        "Знание устройства автомобилей и технологий ремонта.",
        "Опыт работы кузовщиком от года.",
      ],
      conditions: [
        "График оговаривается индивидуально.",
        "Инструмент и оборудование предоставляется.",
        "Дружный коллектив.",
        "Работа в стабильной компании.",
      ],
    },
  },
  {
    name: "Мастер-приёмщик",
    id: 5,
    price: "70 000 ₽ – 100 000 ₽",
    description: {
      work_experience: "1 - 3 года",
      duties: [
        "Принимать и выдавать автомобили согласно стандартам.",
        "Контролировать рабочие процессы.",
        "Консультировать клиентов, согласовывать сроки и объёмы работ.",
        "Работать с входящими звонками.",
        "Работать в CRM-системе (заказ-наряд, акт выполненных работ).",
        "Поддерживать высокие стандарты клиентского сервиса.",
      ],
      requirements: [
        "Опыт работы на аналогичной должности (мастер-приёмщик) от 6 месяцев техническая грамотность (четкое представление процесса ремонта и обслуживания автомобилей).",
        "Грамотная речь, коммуникабельность, забота о клиенте.",
        "Опыт работы с CRM или/и готовность обучаться.",
        "Стремление узнавать новое и быть лучшим в своём деле.",
      ],
      conditions: [
        "График оговаривается индивидуально.",
        "Дружный коллектив.",
        "Работа в стабильной компании.",
      ],
    },
  },
];

export const MainSection = () => {
  const [activeJob, setActiveJob] = useState<number | null>(
    isMobile ? null : 1
  );
  const activeJobItem = mokJobs.find((item) => item.id === activeJob);

  const [containerHeight, setContainerHeight] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (isMobile) {
    }
  }, [activeJob]);

  const navigate = useNavigate();

  return (
    <Container
      className={"h-100"}
      style={{
        minHeight: isMobile && activeJob ? containerHeight : "fit-content",
      }}
    >
      <div className={"d-flex flex-column justify-content-between h-100"}>
        <Row className={"g-3 flex-grow-1"}>
          <S.MainBlock>
            <S.LeftColumn>
              <BrowserView>
                <CustomBackButton path={"/service"} className="lite-back-btn">
                  <FontAwesomeIcon icon={faAngleLeft} size={"sm"} />
                  &nbsp;&nbsp;Вернуться
                </CustomBackButton>
              </BrowserView>
              <S.Header>Вакансии</S.Header>
              <div
                className="cited-mobile_divider"
                style={{ marginLeft: isMobile ? "16px" : "0" }}
              ></div>
              <S.LabelsColumn>
                {mokJobs.map((item) => {
                  return (
                    <S.Label
                      key={item.name}
                      active={activeJob === item.id}
                      onClick={() =>
                        isMobile
                          ? navigate(`/service/job/${item.id - 1}`)
                          : setActiveJob(item.id)
                      }
                    >
                      <div>
                        <p>{item.name}</p>
                        {item.price}
                      </div>
                      {isMobile ? (
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 18.5L16 12.5L10 6.5"
                            stroke="#222222"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3333 24.5L21.3333 16.5L13.3333 8.5"
                            stroke="#222222"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </S.Label>
                  );
                })}
              </S.LabelsColumn>
            </S.LeftColumn>
            <S.RightColumn>
              {activeJobItem && (
                <S.JobLargeCard id={"job-card"}>
                  <S.JobHeader>
                    <p>{activeJobItem.name}</p>
                    {activeJobItem.price}
                  </S.JobHeader>
                  <S.JobDescriptionColumn>
                    <S.JobDescriptionSection>
                      <p>
                        Требудемый опыт работы:{" "}
                        {activeJobItem.description.work_experience}
                      </p>
                      <p>
                        {" "}
                        <img
                          src={Calendar}
                          alt="Календарь"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        Полная занятость
                      </p>
                      <p style={{margin: '0'}}>
                        <img
                          src={Shield}
                          alt="Календарь"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        Оформление по ТД РФ
                      </p>
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        ОБЯЗАННОСТИ:
                      </p>
                      {activeJobItem.description.duties.map((duty, index, array) => {
                        return (
                          <p key={index} style={index === array.length - 1 ? {margin: '0'} : {}}>
                            <img
                              src={Point}
                              alt="Точка"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {duty}
                          </p>
                        );
                      })}
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        ТРЕБОВАНИЯ:
                      </p>
                      {activeJobItem.description.requirements.map(
                        (requirement, index, array) => {
                          return (
                            <p key={index} style={index === array.length - 1 ? {margin: '0'} : {}}>
                              <img
                                src={Point}
                                alt="Точка"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {requirement}
                            </p>
                          );
                        }
                      )}
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        УСЛОВИЯ:
                      </p>
                      {activeJobItem.description.conditions.map(
                        (condition, index, array) => {
                          return (
                            <p key={index} style={index === array.length - 1 ? {margin: '0'} : {}}>
                              <img
                                src={Point}
                                alt="Точка"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {condition}
                            </p>
                          );
                        }
                      )}
                    </S.JobDescriptionSection>
                    <S.FloatButton>
                      <JobRequestForm
                        text={
                          <span className={"order-call_button"}>
                            Откликнуться
                          </span>
                        }
                        job={activeJobItem}
                        small={true}
                        light={false}
                      />
                    </S.FloatButton>
                  </S.JobDescriptionColumn>
                </S.JobLargeCard>
              )}
            </S.RightColumn>
          </S.MainBlock>
        </Row>
      </div>
    </Container>
  );
};
