import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

import "./CustomCarousel.scss";
import { TypeImages } from "../../pages/Rent/RentCarImagesCarousel";
import ImageDoesNotExist from "../ImageDoesNotExists/ImageDoesNotExists";
import classNames from "classnames";
import { LargeViewGallery } from "../LargeViewGallery/LargeViewGallery";
import { isMobile } from "react-device-detect";

type CustomCarousel = {
  images: TypeImages[];
  className?: string | undefined;
};

const CustomCarousel: React.FC<CustomCarousel> = ({ images, className }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const containerClasses = classNames({
    customCarousel: true,
    [className || ""]: Boolean(className),
  });

  const [isLargeViewOpen, setIsLargeViewOpen] = useState<boolean>(false);
  const [largeViewIndex, setLargeViewIndex] = useState<number>(0);

  return (
    <div className={containerClasses}>
      <Carousel
        controls={images.length > 1}
        indicators={false}
        activeIndex={index}
        onSelect={handleSelect}
      >
        {images.map((item) => {
          return (
            <Carousel.Item
              key={item.id}
              onClick={() => {
                if (!isMobile) {
                  return;
                } else {
                  setLargeViewIndex(images.indexOf(item));
                  setIsLargeViewOpen(true);
                }
              }}
            >
              {item.image ? (
                <img
                  className={"customCarouselImage"}
                  src={item.image}
                  alt="car image"
                />
              ) : (
                <div className={"customCarouselImageDoesNotExistsWrapper"}>
                  <ImageDoesNotExist />
                </div>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>

      {images.length > 1 && (
        <div className={"CarouselBottomLibrary"}>
          {images.map((item, i) => (
            <img
              onClick={() => handleSelect(i)}
              key={item.id}
              src={item.image}
              alt="car image"
            />
          ))}
        </div>
      )}

      {isLargeViewOpen && (
        <LargeViewGallery
          onClose={() => setIsLargeViewOpen(false)}
          open={isLargeViewOpen}
          images={images}
          currentIndex={largeViewIndex}
        />
      )}
    </div>
  );
};

export default CustomCarousel;
