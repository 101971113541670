import React, { useEffect, useState } from "react";
import IndexCalculatorSlider from "./IndexCalculatorSlider";
import Animator from "../../../Animator";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useQuery } from "@tanstack/react-query";
import api from "../../../core/axios";

type IndexCalculatorProps = {
  hidePrice?: boolean;
  noAnim?: boolean;
  price?: number;
  maxPrice?: number;
  minPrice?: number;
  priceStep?: number;
  prepaid?: number;
  maxPrepaid?: number;
  minPrepaid?: number;
  prepaidStep?: number;
  time?: number;
  maxTime?: number;
  minTime?: number;
  daysInMonth?: number;
  fontBold?: boolean;
  smallValue?: boolean;
  wideSpace?: boolean;
  className?: string;
  koef?: number;
  calculateInterestRate?: (values: IndexCalculatorValues) => number;
};

type IndexCalculatorValues = {
  price: number;
  prepaid: number;
  minPrepaid: number;
  maxPrepaid: number;
  localMaxPrepaid: number;
  time: number;
};

function defaultCalculateInterest(values: IndexCalculatorValues) {
  return values.prepaid > 150000 ? 0.013 : 0.011;
}

const taxiList: {
  name: string;
  monthPrice: number;
  taxipay: {
    [day: number]: number;
  };
  rentpay: {
    [day: number]: number;
  };
}[] = [
  {
    name: "Toyota Camry",
    monthPrice: 105000,
    taxipay: {
      2: 4500,
      6: 4200,
      13: 4000,
      29: 3700,
      30: 3500,
    },
    rentpay: {
      3: 2800,
      6: 3000,
      14: 3150,
      15: 1000,
    },
  },
  {
    name: "Geely Coolray",
    monthPrice: 69000,
    taxipay: {
      2: 3500,
      6: 3100,
      13: 2700,
      29: 2500,
      30: 2300,
    },
    rentpay: {
      3: 2900,
      6: 3000,
      14: 3150,
      15: 1000,
    },
  },
  {
    name: "Cherry Tiggo 7 Pro",
    monthPrice: 78000,
    taxipay: {
      2: 3400,
      6: 3200,
      13: 3000,
      29: 2800,
      30: 2600,
    },
    rentpay: {
      3: 9800,
      6: 3000,
      14: 3150,
      15: 1000,
    },
  },
  {
    name: "Cherry Tiggo 7 Pro Max",
    monthPrice: 84000,
    taxipay: {
      2: 3600,
      6: 3400,
      13: 3200,
      29: 3000,
      30: 2800,
    },
    rentpay: {
      3: 2800,
      6: 3000,
      14: 3150,
      15: 1000,
    },
  },
];

const IndexCalculatorPeriodIndicator: React.FC<{
  amount: number;
  suffix?: string;
  comment?: string;
  right?: boolean;
  bold?: boolean;
}> = (props) => {
  return (
    <div
      className={
        `indexCalculatorPeriodIndicator ` +
        (props.right ? " text-end" : "") +
        (props.bold ? " font-weight-medium" : "")
      }
    >
      {props.amount.toLocaleString()}
      <span>{props.suffix ?? ""}</span>
      <span
        className={
          "indexCalculatorPeriodIndicatorComment" +
          (props.bold ? " font-weight-medium" : "")
        }
      >
        {props.comment ?? ""}
      </span>
    </div>
  );
};

const IndexCalculator: React.FC<IndexCalculatorProps> = (props) => {
  const [rentList, setRentList] = useState<
    {
      name: string;
      monthPrice: number;
      taxipay: {
        [day: number]: number;
      };
      rentpay: {
        [day: number]: number;
      };
    }[]
  >([]);
  const { data, error, refetch } = useQuery({
    queryKey: ["main page calc"],
    queryFn: () => api.get(`/voshod-auto/?w=maincalc`),
  });
  useEffect(() => {
    if (data && data.data) {
      const arr = data.data.list.map((item: any) => {
        return {
          name: `${item.brand} ${item.model}`,
          monthPrice: item.price.month,
          rentpay: {
            3: item.price.day * 3,
            6: item.price.day * 6,
            14: item.price.day * 14,
            15: item.price.day * 15,
          },
        };
      });
      setRentList(arr);
      setRentModel(arr[0].name);
    }
  }, [data]);

  const [values, setValues] = useState<IndexCalculatorValues>({
    price: props.price ?? 3000000,
    prepaid: props.prepaid ?? 300000,
    minPrepaid: props.minPrepaid ?? 100000,
    maxPrepaid: props.maxPrepaid ?? 5000000,
    localMaxPrepaid: props.maxPrepaid ?? 10000000,
    time: props.time ?? 24,
  });
  useEffect(function () {
    if (!props.noAnim) {
      Animator.animateOnShow(
        "logo_calc",
        [{ id: "logo_calc", delay: 500 }],
        false
      );
    }
  }, []);

  function getClosestValue(obj: { [day: number]: number }, num: number) {
    // Получаем массив ключей, преобразуем их в числа и сортируем по возрастанию
    const keys = Object.keys(obj)
      .map(Number)
      .sort((a, b) => a - b);

    // Находим первый ключ, который больше или равен числу
    const closestKey = keys.find((key) => key >= num);

    // Если ключ найден, возвращаем соответствующее значение
    if (closestKey !== undefined) {
      return obj[closestKey];
    }

    // Если ключа больше нет, возвращаем значение по максимальному ключу
    const maxKey = keys[keys.length - 1];
    return obj[maxKey];
  }

  const monthSum = () => {
    let base = values.price - values.prepaid;
    let interestRate = (props.koef ?? 1.5) / 100;
    return Math.round(((base / values.time + base * interestRate) * 100) / 100); // proverit nado
  };
  const daySum = () => {
    return Math.round(monthSum() / (props.daysInMonth ?? 30));
  };

  const handleUpdate = (value) => {
    let newValue: IndexCalculatorValues = { ...values, ...value };
    newValue.localMaxPrepaid = newValue.maxPrepaid;
    if (newValue.price < newValue.localMaxPrepaid)
      newValue.localMaxPrepaid = newValue.price;
    if (newValue.prepaid > newValue.localMaxPrepaid)
      newValue.prepaid = newValue.localMaxPrepaid;
    setValues(newValue);
  };

  const navigate = useNavigate();
  const [purpose, setPurpose] = useState<"myself" | "taxi">("taxi");
  const [taxiModel, setTaxiModel] = useState<string | undefined>(
    taxiList[0].name
  );
  const [rentModel, setRentModel] = useState<string | undefined>(undefined);

  return (
    <div
      className={
        "d-flex align-items-start justify-content-center flex-column w-100 " +
        (props.noAnim ? "" : " anim-enter-bottom-2 ") +
        (props.className ?? "")
      }
      id={"logo_calc"}
    >
      {/* {!props.hidePrice && (
				<IndexCalculatorSlider
					wideSpace={props.wideSpace}
					className={"calculator-slider w-100 "}
					max={props.maxPrice ?? 10000000}
					min={props.minPrice ?? 1500000}
					step={props.priceStep ?? 50000}
					value={values.price}
					label={"Стоимость автомобиля"}
					valueSuffix={" ₽"}
					onChange={(e) => handleUpdate({ price: e })}
				/>
			)} */}
      {/* <IndexCalculatorSlider
				wideSpace={props.wideSpace}
				className={"w-100 calculator-slider "}
				min={values.minPrepaid}
				max={values.localMaxPrepaid}
				step={props.prepaidStep ?? 5000}
				value={values.prepaid}
				onChange={(e) => handleUpdate({ prepaid: e })}
				label={"Первый взнос"}
				valueSuffix={" ₽"}
				labelClass={props.fontBold ? "font-weight-medium" : ""}
				valueClass={
					props.fontBold
						? "font-weight-medium"
						: "" + (props.smallValue ? " font-size-20" : "")
				}
			/> */}
      <div
        className={"d-flex flex-column gap-18"}
        style={{ marginBottom: isMobile ? "17px" : "40px" }}
      >
        <div
          className={"d-flex"}
          style={{
            marginBottom: isMobile ? "24px" : "40px",
            gap: isMobile ? "8px" : "15px",
          }}
        >
          <button
            className={`site-btn ${
              isMobile ? "index-btn-mobile" : "extra-small"
            } ${purpose === "myself" ? "fill-red" : "light"} regular_text`}
            onClick={() => setPurpose("myself")}
          >
            <>Для себя</>
          </button>
          <button
            className={`site-btn ${
              isMobile ? "index-btn-mobile" : "extra-small"
            } ${purpose === "taxi" ? "fill-red" : "light"} regular_text`}
            onClick={() => setPurpose("taxi")}
          >
            <>Для такси</>
          </button>
        </div>
        <div
          className={"d-flex"}
          style={{
            marginBottom: isMobile ? "8px" : "15px",
            gap: isMobile ? "8px" : "15px",
          }}
        >
          {(purpose === "myself" ? rentList : taxiList)
            .slice(0, 2)
            .map((car: { name: string }) => {
              return (
                <button
                  key={car.name}
                  className={`site-btn ${
                    isMobile ? "index-btn-mobile" : "extra-small"
                  } ${
                    (purpose === "myself" ? rentModel : taxiModel) === car.name
                      ? "fill-red"
                      : "light"
                  } regular_text`}
                  onClick={() =>
                    purpose === "myself"
                      ? setRentModel(car.name)
                      : setTaxiModel(car.name)
                  }
                >
                  <>{car.name}</>
                </button>
              );
            })}
        </div>
        <div
          className={"d-flex"}
          style={{
            marginBottom: isMobile ? "24px" : "15px",
            gap: isMobile ? "8px" : "15px",
          }}
        >
          {(purpose === "myself" ? rentList : taxiList)
            .slice(2, 4)
            .map((car: { name: string }) => {
              return (
                <button
                  key={car.name}
                  className={`site-btn ${
                    isMobile ? "index-btn-mobile" : "extra-small"
                  } ${
                    (purpose === "myself" ? rentModel : taxiModel) === car.name
                      ? "fill-red"
                      : "light"
                  } regular_text`}
                  onClick={() =>
                    purpose === "myself"
                      ? setRentModel(car.name)
                      : setTaxiModel(car.name)
                  }
                >
                  <>{car.name}</>
                </button>
              );
            })}
        </div>
        <div
          className={"d-flex gap-px-15"}
          style={{ marginBottom: isMobile ? "8px" : "15px" }}
        >
          <button
            className={`site-btn d-flex gap-2 align-items-center ${
              isMobile ? "index-btn-mobile" : "extra-small"
            } light regular_text`}
            onClick={() =>
              purpose === "myself"
                ? navigate("/rent/page/1")
                : navigate("/rent/page/1")
            }
            style={{ maxWidth: "fit-content" }}
          >
            Посмотреть другие автомобили{" "}
            <svg
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 5.5L9.27642 1.5M13 5.5L9.27642 9.5M13 5.5H1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>

      <IndexCalculatorSlider
        wideSpace={props.wideSpace}
        className={"calculator-slider"}
        min={props.minTime ?? 1}
        max={props.maxTime ?? 30}
        step={1}
        value={values.time}
        onChange={(e) => handleUpdate({ time: e })}
        label={"Срок"}
        valueSuffix={" д."}
        labelClass={props.fontBold ? "font-weight-medium" : ""}
        valueClass={
          props.fontBold
            ? "font-weight-medium"
            : "" + (props.smallValue ? " font-size-20" : "")
        }
      />
      <div
        className={"d-flex justify-content-between calculator-slider-value "}
      >
        {/* mt-px-20 */}
        <IndexCalculatorPeriodIndicator
          amount={
            purpose === "myself"
              ? getClosestValue(
                  rentList.find((item) => item.name === rentModel)?.rentpay ??
                    {},
                  values.time
                )
              : getClosestValue(
                  taxiList.find((item) => item.name === taxiModel)?.taxipay ??
                    {},
                  values.time
                )
          }
          suffix={" ₽"}
          comment={"/ в сутки"}
          bold={props.fontBold}
        />
        <IndexCalculatorPeriodIndicator
          amount={
            (purpose === "myself" ? rentList : taxiList).find(
              (item) =>
                item.name === (purpose === "myself" ? rentModel : taxiModel)
            )?.monthPrice || 0
          }
          suffix={" ₽"}
          comment={"/ в месяц"}
          bold={props.fontBold}
        />
      </div>
    </div>
  );
};

export default IndexCalculator;
