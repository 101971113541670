import React, { ReactNode, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseLayout, { MetaTags } from "../layout/BaseLayout";
import caret from "./../../images/common/caret-up-white.png";
import ContactsForm from "../common/ContactsForm";
// import {faClock, faLocationDot, faPhoneVolume} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import vkW from "./../../images/common/footer/social-icons-dark/vk.svg";
import wappW from "./../../images/common/footer/social-icons-dark/whatsapp.svg";
import teleW from "./../../images/common/footer/social-icons-dark/telegram.svg";

import {
  FooterBottom,
  FooterContacts,
  FooterContactsBlock,
  FooterLink,
} from "../layout/Footer";
import geoImage from "../../images/common/footer/contct-page-icons/Geolocation.svg";
import clockImage from "../../images/common/footer/contct-page-icons/Clock 2.svg";
import phoneImage from "../../images/common/footer/contct-page-icons/Phone 2.svg";
import ContactFeedBackForm from "../common/ContactFeedBackForm";
import MetaDecorator from "../layout/MetaDecorator";
import { Map } from "../common/YandexMap/YandexMap";
import { isMobile } from "react-device-detect";

const ContactBlock: React.FC<{
  icon: ReactNode;
  header: string | ReactNode;
  children: any;
}> = (props) => (
  <div className={"position-relative ps-px-45 mb-px-50"}>
    <div className={"position-absolute start-0"} style={{ top: "5px" }}>
      {props.icon}
    </div>
    <div
      className={"font-size-24 text-uppercase font-weight-semibold mb-px-15"}
    >
      {props.header}
    </div>
    <div className={"font-size-16 font-weight-regular"}>{props.children}</div>
  </div>
);

const ContactSocial: React.FC<{ icon: string; hover: string; link: string }> = (
  props
) => (
  <Link to={props.link} className={"contacts-social"}>
    <img src={props.icon} className={"default-image"} alt="" />
    <img src={props.hover} className={"hover-image"} alt="" />
  </Link>
);

const ContactsPage = () => {
  const title = "Контакты - " + process.env.REACT_APP_WEBSITE_NAME;
  const meta: MetaTags = {
    description: "Наши контакты",
    keywords:
      "контакты,телефон,email,vk,vkontakte,telegram,phone,мейл,имейл,почта",
  };
  const lines = process.env.REACT_APP_ADDRESS?.split("\n") ?? [];
  const lines2 = process.env.REACT_APP_SERVICE_ADDRESS?.split("\n") ?? [];
  const lines3 = process.env.REACT_APP_SECOND_ADDRESS?.split("\n") ?? [];

  const [show, setShow] = useState(false);
  const [upper, setUpper] = useState(false);

  useEffect(() => {
    let handler = () => {
      if (window.pageYOffset > 460 && !show) setShow(true);
      else if (window.pageYOffset <= 460 && !show) setShow(false);
    };

    window.addEventListener("scroll", handler);
    // return () => {
    //     window.removeEventListener('scroll', handler);
    // }
  });

  return (
    <>
      <MetaDecorator title={title} url="/contacts" />
      <BaseLayout
        meta={meta}
        title={title}
        footerNoContacts={true}
        footerNoForm={true}
        headerSelectedLink={"/contacts"}
        noFooter
        noScroll
      >
        {/*<FooterContacts/>*/}
        <div className="contacts-page ">
          <Container fluid={"xxl"}>
            <Row className={"gx-3"}>
              <Col sm={12} md={6}>
                <div
                  className=" contacts-page_top"
                  style={{ fontSize: "52px" }}
                >
                  <div
                    className="cited-header-without-m"
                    style={{ fontSize: isMobile ? "32px" : "52px" }}
                  >
                    Контакты
                  </div>
                </div>
                <div
                  className={"cited-mobile_divider"}
                  style={{ width: "44px", height: "3px" }}
                />
                <div className="contacts-page_blocks">
                  <FooterContactsBlock
                    theme="light"
                    image={geoImage}
                    text={"БЦ “Гулливер”"}
                  >
                    {lines.map((i, index) => (
                      <div
                        className={`line-height-120 `}
                        style={{
                          fontSize: isMobile ? "12px" : "",
                          marginBottom: index !== lines.length - 1 ? "4px" : "",
                        }}
                      >
                        {i.startsWith("+7") ? (
                          <a href={`tel:${i}`} style={{ color: "black" }}>
                            {i}
                          </a>
                        ) : (
                          i.replace("СПб, ", "")
                        )}
                      </div>
                    ))}
                  </FooterContactsBlock>
                  <FooterContactsBlock
                    theme="light"
                    image={geoImage}
                    text={"ТЦ “Славянский базар”"}
                  >
                    {lines3.map((i, index) => (
                      <div
                        className={`line-height-120 `}
                        style={{
                          fontSize: isMobile ? "12px" : "",
                          marginBottom: index !== lines.length - 1 ? "4px" : "",
                        }}
                      >
                        {i.startsWith("+7") ? (
                          <a href={`tel:${i}`} style={{ color: "black" }}>
                            {i}
                          </a>
                        ) : (
                          i.replace("СПб, ", "")
                        )}
                      </div>
                    ))}
                    {/* пн-вс: с 10.00 - 19.00 */}
                  </FooterContactsBlock>
                  <FooterContactsBlock
                    theme="light"
                    image={geoImage}
                    text={"Автосервис"}
                  >
                    {lines2.map((i, index) => (
                      <div
                        key={index}
                        className={`line-height-120 `}
                        style={{
                          fontSize: isMobile ? "12px" : "",
                          marginBottom: index !== lines.length - 1 ? "4px" : "",
                        }}
                      >
                        {i.startsWith("+7") ? (
                          <a href={`tel:${i}`} style={{ color: "black" }}>
                            {i}
                          </a>
                        ) : (
                          i.replace("СПб, ", "")
                        )}
                      </div>
                    ))}
                  </FooterContactsBlock>
                  {/* <FooterContactsBlock
                    theme="light"
                    image={phoneImage}
                    text={"Телефон"}
                  >
                    <a
										href={
											"tel:" +
											Utils.cleanPhone(process.env.REACT_APP_PHONE ?? "")
										}>
										{process.env.REACT_APP_PHONE}
									</a>
                    +7 (812) 317-68-15
                  </FooterContactsBlock> */}
                </div>
                <div className="footer-contacts-page_lines d-block d-sm-none">
                  <div className="footer-contacts-page_lines_line" />
                  <div className="footer-contacts-page_lines_dot" />
                  <div className="footer-contacts-page_lines_dot" />
                  <div className="footer-contacts-page_lines_dot" />
                  <div className="footer-contacts-page_lines_dot" />
                  <div className="footer-contacts-page_lines_dot" />
                </div>
                <div
                  className={"contacts-page_links contacts-links"}
                  style={
                    isMobile
                      ? { position: "absolute", right: "16px", bottom: "16px" }
                      : {}
                  }
                >
                  <FooterLink
                    img={vkW}
                    link={process.env.REACT_APP_VK_LINK ?? "/"}
                  />
                  <FooterLink
                    img={wappW}
                    link={process.env.REACT_APP_WAPP_LINK ?? "/"}
                  />
                  <FooterLink
                    img={teleW}
                    link={process.env.REACT_APP_TELEGRAM_LINK ?? "/"}
                  />
                </div>

                {isMobile && <FooterBottom />}
              </Col>
              <Col
                sm={12}
                md={6}
                className=" footer-contacts-page_contact-form"
              >
                {/* <ContactsForm big={true} /> */}
                <ContactFeedBackForm />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-contacts-page_map footer__map">
          {show && (
            <button
              className="footer-contacts-page_map_button site-btn dark"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              {" "}
              <img src={caret} alt="up" />
              Вернуться к контактам <img src={caret} alt="up" />
            </button>
          )}
          <Map />
        </div>
      </BaseLayout>
    </>
  );
};

export default ContactsPage;
