import { Col, Container, Row } from "react-bootstrap";
import "./MainStoCard.scss";
import bg from "./../../../../images/sto-back-2.webp";
import { isMobile, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import ServiceRequestForm from "../ServiceForm";

export const MainStoCard = () => {
  return (
    <div className={"service__logo"} style={{ backgroundImage: `url(${bg})` }}>
      <div className={"service__logo-overlay"}></div>
      <div className={"service__logo-content"}>
        <Container className={"h-100"} style={{padding: '0 16px'}}>
          <div className={"d-flex flex-column justify-content-between h-100"}>
            <Row className={"g-3 flex-grow-1"}>
              <Col sm={12} md={8} lg={5} className="m-md-0" id={"logo-text"}>
                <div className={" service__logo-header"}>
                  <div>Доступный</div>
                  <div className={"muted"}>автосервис</div>
                </div>
                {/* <div className={"fs-3 mb-px-40"}>Страница в разработке</div> */}
                <div
                  className={"service__logo-comment"}
                  style={{ marginBottom: "40px" }}
                >
                  Автосервис оказывает широкий спектр услуг по диагностике,{!isMobile && <br/>}
                  ремонту и техническому обслуживанию легковых автомобилей.
                  Основная специализация – обслуживание автомобилей таксопарков,
                  где особенно важны качество и скорость исполнения работ.
                </div>
                <ServiceRequestForm
                  text={
                    <span className={"order-call_button"} style={{fontSize: '16px', lineHeight: 'normal'}}>
                      Записаться
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 17 17"
                        fill="none"
                      >
                        <path
                          d="M7.16602 12.5L11.166 8.5L7.16602 4.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  }
                  small={true}
                  light={true}
                />
                <p className="invite_text">
                  Приглашаем к сотрудничеству компании и организации.
                </p>
                <p className="invite_lighter ">
                  Возможно заключение договора на комплексное обслуживание автомобилей.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};
