import React from 'react';
import * as S from './OfferPage.styled'

export const DocTitle:React.FC<{text?:string,children?:any}> = (props) => {
    return (
        <S.DocTile>{props.text ?? props.children}</S.DocTile>
    )
}
export const DocParagraph:React.FC<{children?:any, padded?:boolean, prefix?:string}> = (props) => {
    return (
        
            <S.DocParagraphStyled>
                {props.prefix && <strong>{props.prefix}</strong>}
                {props.children}
            </S.DocParagraphStyled>
       
    )
}
export const DocParagraphFlat:React.FC<{children?:any, padded?:boolean, prefix?:string}> = (props) => {
    return (
        
            <S.DocParagraphFlatStyled>
                {props.prefix && <strong>{props.prefix}</strong>}
                {props.children}
            </S.DocParagraphFlatStyled>
       
    )
}
export const DocChapter:React.FC<{header?:string,children?:any}> = (props) => {
    return (
        <>
            {props.header && <DocTitle text={props.header}></DocTitle>}
            {props.children}
        </>
    )
}
const DocPage:React.FC<{children:any, header:string}> = (props) => {
    return (
        <div className={'document-page'}>
            <div className="document-title">{props.header}</div>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default DocPage;