import BaseLayout from "../layout/BaseLayout";
import counter1 from "./../../images/index/counter1.svg";
import counter2 from "./../../images/index/counter2.svg";
import counter3 from "./../../images/index/counter3.svg";
import rhombus from "./../../images/index/rhombus.svg";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { mockFaq } from "../../Api";
import FoldableQuestion from "../common/FoldableQuestion";
import { isMobile } from "react-device-detect";

export const ConditionsPage = () => {
  const [faq, setFaq] = useState<any>();
  const [page, setPage] = useState("buyout");
  useEffect(() => {
    setFaq({
      buyout: mockFaq("buyout"),
      rent: mockFaq("rent"),
    });
  }, []);
  const FaqBuyout = () => {
    const questions1 = faq?.buyout.faq?.slice(
      0,
      Math.ceil(faq.buyout.faq.length / 2)
    );
    const questions2 = faq?.buyout.faq?.slice(questions1.length);
    return (
      <Row className={"gx-lg-5 gx-sm-0"}>
        <Col md={6}>
        <div className="foldable-list">
            {questions1?.map((i, ind) => (
              <FoldableQuestion key={ind} header={i.title}>
                {i.text}
              </FoldableQuestion>
            ))}
          </div>
        </Col>
        <Col md={6} className={"d-flex justify-content-end"}>
          <div className={"w-100"}>
            {questions2?.map((i, ind) => (
              <FoldableQuestion key={ind} header={i.title}>
                {i.text}
              </FoldableQuestion>
            ))}
          </div>
        </Col>
      </Row>
    );
  };
  const FaqRent = () => {
    const questions1 = faq?.rent.faq?.slice(
      0,
      Math.ceil(faq.rent.faq.length / 2)
    );
    const questions2 = faq?.rent.faq?.slice(questions1.length);
    return (
      <Row className={"gx-lg-5 gx-sm-0"}>
        <Col md={6}>
          <div>
            {questions1?.map((i, ind) => (
              <FoldableQuestion key={ind} header={i.title}>
                {i.text}
              </FoldableQuestion>
            ))}
          </div>
        </Col>
        <Col md={6}>
          <div>
            {questions2?.map((i, ind) => (
              <FoldableQuestion key={ind} header={i.title}>
                {i.text}
              </FoldableQuestion>
            ))}
          </div>
        </Col>
      </Row>
    );
  };
  const FirstBlock = () => (
    <div>
      <Container fluid={"xxl"}>
        <div
          className={"cited"}
          style={{
            margin: isMobile ? "24px 0 40px 0" : "65px 0 80px 0",
            boxShadow: "none",
          }}
        >
          <div
            className={"cited-header-without-m"}
            style={{ marginBottom: "10px" }}
          >
            Условия
          </div>

          <div className={"cited-attention"}>
            Обращаем ваше внимание!
            <br />
            Информация, размещенная на сайте, носит информационный характер и не
            является публичной офертой.
          </div>
          <div className={"cited-mobile_divider"} />
        </div>
      </Container>
    </div>
  );
  const title = "Условия - " + process.env.REACT_APP_WEBSITE_NAME;
  const Condition = ({
    text,
    icon,
    add_text,
    wrap,
  }: {
    text?: string;
    icon?: any;
    add_text?: string;
    wrap?: boolean;
    nowrapadd?: boolean;
  }) => {
    return (
      <div
        className={"arrange-leasing_options-item"}
        style={{ maxWidth: "800px" }}
      >
        {icon && <img src={icon} alt="count" />}
        
        {text && (
          <p>
            {isMobile
              ? text.split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))
              : text}
            {wrap && <br />}
            {add_text && (
              <span style={{ color: "#606569"}}>
                {add_text}
              </span>
            )}
          </p>
        )}
      </div>
    );
  };
  return (
    <>
      <BaseLayout title={title} headerSelectedLink="/conditions">
        <FirstBlock />
        <Container>
          <div className={isMobile ? "mb-px-40" : "mb-px-60"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              КАК АРЕНДОВАТЬ АВТОМОБИЛЬ?
            </div>
            <Condition
              icon={counter1}
              text={
                "Забронировать автомобиль онлайн самостоятельно или позвонить и уточнить наличие свободных автомобилей,\n или приехать и выбрать лично"
              }
            />
            <Condition
              icon={counter2}
              text={"Подписать договор аренды автомобиля"}
            />
            <Condition
              icon={counter3}
              text={"Внести оплату (залог + стоимость аренды)"}
            />
          </div>
          <div className={isMobile ? "mb-px-40" : "mb-px-60"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              ОБЩИЕ ТРЕБОВАНИЯ:
            </div>
            <Condition icon={rhombus} text={"Возраст не менее 23 лет"} />
            <Condition icon={rhombus} text={"Водительский стаж от 3-х лет"} />
            <Condition
              icon={rhombus}
              text={"Действующее водительское удостоверение категории «В»"}
            />
            <div
              className={"arrange-leasing_options-item"}
              style={{ maxWidth: "1200px" }}
            >
              <img src={rhombus} alt="count" />
              
              <p>
                {isMobile
                  ? "Паспорт РФ".split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))
                  : "Паспорт РФ"}
                <span style={{ color: "#606569"}}>
                  {
                    " (для иностранных граждан: паспорт иностранного гражданина + миграционная карта / виза / вид на жительство)"
                  }
                </span>
              </p>
            </div>
            <Condition
              icon={rhombus}
              text={
                "Постоянная или временная регистрация сроком не менее 6 месяцев на территории РФ"
              }
            />
          </div>
          <div className={isMobile ? "mb-px-40" : "mb-px-60"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              УСЛОВИЯ АРЕНДЫ:
            </div>
            <Condition
              icon={rhombus}
              text={"Автомобили исправны и не старше 3 лет"}
            />
            <Condition
              icon={rhombus}
              text={"Все автомобили застрахованы (ОСАГО, КАСКО)"}
            />
            <Condition icon={rhombus} text={"Лицензия для работы в такси"} />
            <Condition icon={rhombus} text={"Автомобили чистые и заправлены"} />
            <Condition
              icon={rhombus}
              text={"Минимальное время проката от 2 суток"}
              add_text={"(максимальное не ограничено)"}
            />
          </div>
          <div className={isMobile ? "mb-px-40" : "mb-px-60"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              УСЛОВИЯ ВОЗВРАТА:
            </div>
            <Condition
              icon={rhombus}
              text={
                "Возврат автомобиля осуществляется\n в период с 10 до 11 утра"
              }
            />
            <Condition
              icon={rhombus}
              text={
                "Сообщить о сдаче автомобиля необходимо не позднее, чем за 2-е суток"
              }
            />

            <Condition
              icon={rhombus}
              text={"Автомобиль должен быть чистым и заправлен"}
            />
          </div>
          <div className={isMobile ? "mb-px-40" : "mb-px-60"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              ТЕРРИТОРИЯ ЭКСПЛУАТАЦИИ
            </div>
            <Condition
              icon={rhombus}
              text={"Ленинградская область — без ограничений"}
            />
            <Condition
              icon={rhombus}
              text={"Выезд в пределах РФ — по согласованию"}
            />
            <Condition
              icon={rhombus}
              text={"Запрещен выезд в следующие регионы: "}
              add_text={
                "Республика Дагестан, Республика Ингушетия, Кабардино-Балкарская Республика, Донецкая Народная Республика, Луганская Народная Республика, Херсонская область, Запорожская область, Белгородская область, Крымская область"
              }
              wrap={true}
            />
          </div>
          <div className={isMobile ? "mb-px-40" : "mb-px-50"}>
            <div className="car-stat-block-header" style={{ padding: "0" }}>
              ОПЛАТА
            </div>
            <div className="arrange-leasing_options-item">
              <p>
                {" "}
                Производится 100% предоплата аренды/ проката автомобиля. <br />{" "}
                По окончании срока аренды/ проката возможно продление через
                личный кабинет (самостоятельно) или при личном присутствии
                <br /> в офисе (сотрудником компании) при условии, если данный
                автомобиль свободен (не забронирован).
                <br />
                При возврате автомобиля раньше установленного срока,
                производится перерасчет согласно условиям договора
              </p>
            </div>
            <div className="arrange-leasing_options-item">
              <p className="arrange-leasing_options-item">
                Способы оплаты: <br />
                физ. лицо - наличные, банковская карта, СБП <br />
                юр. лицо – безналичный расчет
              </p>
            </div>
          </div>
        </Container>
        <div
          className={"faq"}
          style={{
            boxShadow: "0px 0px 60px 0px #0000001a inset",
            paddingTop: isMobile ? "24px" : "120px",
          }}
        >
          <Container fluid={"xxl"}>
            <div className={"faq_header d-none d-md-block"}>
              Часто задаваемые вопросы
            </div>
            <div className={"faq_header d-block d-md-none"}>вопросы</div>
            <div className={"faq_description faq_description-top "}>
              Список ответов на часто задаваемые вопросы от наших клиентов
              постоянно пополняется.
              {/* <br /> */}
            </div>
            <div className={"faq_description"}>
              Напишите нам, чтобы мы могли дополнить список.
            </div>

            <div className="nav nav-tabs car-info-btns_mobile d-flex d-md-none">
              <button
                onClick={() => {
                  setPage("rent");
                }}
                className={"nav-link " + (page === "rent" ? "active" : "")}
              >
                Прокат / Аренда
              </button>
              <button
                onClick={() => {
                  setPage("buyout");
                }}
                className={"nav-link " + (page === "buyout" ? "active" : "")}
              >
                Выкуп
              </button>
            </div>

            <div
              className={"car-info-btns justify-content-start d-none d-md-flex"}
            >
              <button
                className={
                  "car-info-btn big " + (page === "rent" ? "active" : "")
                }
                onClick={() => {
                  setPage("rent");
                }}
              >
                Прокат / Аренда
              </button>
              <button
                className={
                  "car-info-btn big " + (page === "buyout" ? "active" : "")
                }
                onClick={() => {
                  setPage("buyout");
                }}
              >
                Выкуп
              </button>
            </div>
            <div className={"faq_questions"}>
              {page === "buyout" && <FaqBuyout />}
              {page === "rent" && <FaqRent />}
            </div>
            <div className={"faq-contacts"}>
              <div className={"faq-contacts-header"}>
                Не нашли ответ на свой вопрос?
              </div>
              <div className={"faq-contacts-text"}>
                Позвоните нам по телефону: +7 (812) 317-68-15
              </div>
              <div className={"faq-contacts-text"}>
                Время работы: с 9:00 до 21:00
              </div>
              <div className={"faq-contacts-text text-red-color"}>
                Звонок бесплатный
              </div>
            </div>
          </Container>
        </div>
        {/* <Container fluid={"xxl"}>
          
        </Container> */}
      </BaseLayout>
    </>
  );
};
